import { ChartIcon, CogIcon, HomeIcon, IconProps } from '@hiven-energy/hiven-ui';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { MessageId } from 'src/i18n';
import { RouteId } from 'src/nav/types';
import { colors } from 'src/theme';

import * as styled from './styles';

interface Tab {
  icon: FC<IconProps>;
  translationMessageId: MessageId;
  color: string;
  activeColor: string;
}

type TabBarRoute = RouteId.DashboardTab | RouteId.SettingsTab | RouteId.AnalyticsTab;

const tabs: Record<TabBarRoute, Tab> = {
  [RouteId.DashboardTab]: {
    icon: HomeIcon,
    translationMessageId: 'navigation.screen.Dashboard',
    color: colors.hintGrey,
    activeColor: colors.deepNavy,
  },
  [RouteId.AnalyticsTab]: {
    icon: ChartIcon,
    translationMessageId: 'navigation.screen.Analytics',
    color: colors.hintGrey,
    activeColor: colors.deepNavy,
  },
  [RouteId.SettingsTab]: {
    icon: CogIcon,
    translationMessageId: 'navigation.screen.Settings',
    color: colors.hintGrey,
    activeColor: colors.deepNavy,
  },
};

const TabBar: FC<BottomTabBarProps> = ({ navigation, state: { index, routes } }) => (
  <styled.Container>
    <styled.TabBar>
      {routes.map((route, routeIndex) => {
        const isFocused = index === routeIndex;
        const tab = tabs[route.name as TabBarRoute];
        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          });
          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name);
          }
        };
        return (
          <styled.Tab key={routeIndex} onPress={onPress}>
            {<tab.icon color={isFocused ? tab.activeColor : tab.color} />}
            <styled.TabName fontSize={14} fontWeight="medium" $color={isFocused ? tab.activeColor : tab.color}>
              <FormattedMessage id={tab.translationMessageId} />
            </styled.TabName>
          </styled.Tab>
        );
      })}
    </styled.TabBar>
  </styled.Container>
);

export default React.memo(TabBar);
