import { Button, EmailIcon, Link, LockedIcon, Modal, TouchableContainer, Typography } from '@hiven-energy/hiven-ui';
import { StatusBar } from 'expo-status-bar';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Platform, ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useToast } from 'react-native-toast-notifications';

import { useA11y } from 'src/a11y';
import { useAppTheme } from 'src/app-theme';
import { TextField } from 'src/components/form-fields/TextField';
import { useFormUserPasswordResolver } from 'src/hooks/useFormUserPasswordResolver';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { CognitoAction, useSessionService } from 'src/services/session';

import { getCognitoErrorMessageId } from '../utils';

import * as styled from './styles';
import { SignUpFormData } from './types';

type Props = ScreenProps<RouteId.SignUp>;

const signUpTitle = { id: 'SignUp.button' };

const SignUp: FC<Props> = ({ route, navigation }) => {
  const intl = useIntl();
  const a11y = useA11y();
  const toast = useToast();
  const sessionService = useSessionService();
  const insets = useSafeAreaInsets();

  const appTheme = useAppTheme();
  const resolver = useFormUserPasswordResolver();

  const { control, handleSubmit, getValues } = useForm<SignUpFormData>({
    mode: 'onBlur',
    defaultValues: { email: route.params?.email || '' },
    resolver,
  });

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useAnalyticsTimeEvent(MixpanelEvents.SIGNED_UP);
  const { trackSignUp, trackButtonClick } = useAnalytics();

  const isWeb = Platform.OS === 'web';

  const handleSignUp = (data: SignUpFormData) => {
    setLoading(true);
    sessionService
      .signUp(data.email, data.password)
      .then(() => {
        trackSignUp(signUpTitle.id, data.email);
        navigation.navigate(RouteId.ConfirmSignUp, { email: data.email });
      })
      .catch(error => {
        const errorMessageId = getCognitoErrorMessageId(CognitoAction.SIGN_UP, error);
        toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSignInPress = () => {
    trackButtonClick('SignUp.loginLink');
    navigation.navigate(RouteId.SignIn, { email: getValues().email });
  };

  const toggleModal = () => {
    trackButtonClick('SignUp.termsLink');
    setModalVisible(state => !state);
  };

  return (
    <styled.Container>
      <styled.Title variant="h3" accessibilityLabel={a11y.formatLabel('SignUp.header')}>
        <FormattedMessage id="SignUp.header" />
      </styled.Title>
      <TextField
        placeholder={intl.formatMessage({ id: 'common.email' })}
        control={control}
        name="email"
        leftIcon={EmailIcon}
      />
      <TextField
        placeholder={intl.formatMessage({ id: 'common.password' })}
        control={control}
        name="password"
        leftIcon={LockedIcon}
        password
      />
      <styled.Terms>
        <styled.TermsText accessibilityLabel={a11y.formatLabel('SignUp.terms')}>
          <FormattedMessage id="SignUp.termsText" />
        </styled.TermsText>
        <Link onPress={toggleModal} accessibilityLabel={a11y.formatLabel('SignUp.termsLink')}>
          <FormattedMessage id="SignUp.termsLink" />
        </Link>
      </styled.Terms>
      <styled.Footer>
        <Button
          disabled={loading}
          loading={loading}
          title={intl.formatMessage(signUpTitle)}
          onPress={handleSubmit(handleSignUp)}
          testID={a11y.formatLabel('SignUp.button')}
        />
        <styled.SignInQuestion>
          <styled.SignInText accessibilityLabel={a11y.formatLabel('SignUp.loginQn')}>
            <FormattedMessage id="SignUp.signInQuestion" />
          </styled.SignInText>
          <Link onPress={handleSignInPress} accessibilityLabel={a11y.formatLabel('SignUp.loginLink')}>
            <FormattedMessage id="SignUp.signInLink" />
          </Link>
        </styled.SignInQuestion>
      </styled.Footer>
      <Modal
        visible={modalVisible}
        onClose={toggleModal}
        styles={{
          overlay: {
            paddingTop: isWeb ? 30 : insets.top + 30,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            backgroundColor: appTheme.main.color,
          },
          root: {
            ...(isWeb && {
              maxHeight: '100%' as unknown as number,
            }),
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
        title={intl.formatMessage({ id: 'SignUp.termsModal.title' })}
      >
        <ScrollView
          contentContainerStyle={{
            paddingBottom: isWeb ? 0 : insets.bottom + 30,
          }}
        >
          <TouchableContainer>
            <Typography>
              <FormattedMessage id="SignUp.termsModal.content" />
            </Typography>
          </TouchableContainer>
        </ScrollView>
      </Modal>
      <StatusBar style="light" />
    </styled.Container>
  );
};

export default SignUp;
