import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import BaseView from 'src/components/BaseView/BaseView';
import ChargeLevels from 'src/containers/charge-levels/Overview/ChargeLevels';
import ReadyTime from 'src/containers/ready-time/Overview/ReadyTime';

import { CHARGE_LEVEL_UNIT } from '../../constants';
import { Preferences, PreferenceType } from '../../types';

import ChargingBehavior from './ChargingBehavior/ChargingBehavior';
import ChargingLocations from './ChargingLocations/ChargingLocations';
import * as styled from './styles';
import { Scroll } from './styles';

interface Props {
  preferences: Preferences;
  locationSupported: boolean;
  saveAllowed: boolean;
  saving: boolean;
  initialLoading: boolean;
  onEditPreferencePress: (preferenceType: PreferenceType) => void;
  onRemoveNewLocation: (index: number) => void;
  onSavePress: VoidFunction;
  isAssociated: boolean;
}

const Overview: FC<Props> = ({
  preferences,
  locationSupported,
  saveAllowed,
  saving,
  initialLoading,
  onEditPreferencePress,
  onSavePress,
  isAssociated,
  onRemoveNewLocation,
}) => {
  const intl = useIntl();
  const a11y = useA11y();

  const handleSave = () => {
    onSavePress();
  };

  return (
    <BaseView>
      <Scroll contentContainerStyle={{ gap: 32, paddingTop: 24 }}>
        <ChargingLocations
          chargingLocations={preferences.chargingLocations}
          onAddLocationPress={() => onEditPreferencePress(PreferenceType.YOUR_CHARGING_LOCATIONS)}
          onRemoveLocation={onRemoveNewLocation}
          analyticsPlace="VehiclePreferences.chargingLocation"
          isLoading={initialLoading}
        />
        <ReadyTime
          timeByType={preferences.readyTime}
          onEditPress={() => onEditPreferencePress(PreferenceType.READY_TIME)}
        />
        <ChargeLevels
          minimum={preferences.chargeLevels.minimum}
          maximum={preferences.chargeLevels.maximum}
          unit={CHARGE_LEVEL_UNIT}
          onEditPress={() => onEditPreferencePress(PreferenceType.CHARGE_LEVELS)}
          analyticsPlace="VehiclePreferences.chargeLevels"
        />
        {locationSupported && (
          <ChargingBehavior
            disabled={isAssociated}
            value={preferences.chargingBehavior}
            onEditPress={() => onEditPreferencePress(PreferenceType.CHARGING_BEHAVIOR)}
          />
        )}
      </Scroll>
      <styled.SaveButton
        title={intl.formatMessage({ id: 'common.save' })}
        loading={saving}
        disabled={!saveAllowed}
        onPress={handleSave}
        testID={a11y.formatLabel('common.save')}
      />
    </BaseView>
  );
};

export default React.memo(Overview);
