import { Container as _Container, spacings } from '@hiven-energy/hiven-ui';
import { Animated, Platform, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

export const Container = styled(_Container)`
  align-items: center;
`;

export const Header = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  gap: ${spacings.xxxs}px;
`;

export const ToggleContent = styled(Animated.View)`
  border-bottom-left-radius: ${Platform.OS === 'android' ? 0 : 24}px;
  border-bottom-right-radius: ${Platform.OS === 'android' ? 0 : 24}px;
  left: 0;
  right: 0;
`;

export const DialogOverlay = styled(_Container)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const AnimatedOpacityContainer = styled(Animated.View)`
  flex: 1;
`;
