import { ChevronDownIcon } from '@hiven-energy/hiven-ui';
import { useIsFocused } from '@react-navigation/native';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Animated,
  Easing,
  Modal,
  Platform,
  StatusBar,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from 'react-native';
import { useDebounce } from 'use-debounce';

import { useAppTheme } from 'src/app-theme';
import { useAnimatedValue } from 'src/hooks/useAnimatedValue';
import { useAnalytics } from 'src/services/analytics';
import { AnalyticPlace } from 'src/services/analytics/types';
import { colors, MAX_CONTENT_WIDTH } from 'src/theme';

import * as styled from './styles';
import { AnimatedOpacityContainer } from './styles';

const DEBOUNCE_MS = 100;

interface TogglePanelProps {
  title: React.ReactNode;
  toggleContent: React.ReactNode | undefined;
  isActive: boolean;
  subTitle?: React.ReactNode;
  startingHeight: number;
  accessibilityLabel: string;
  analyticsPlace: AnalyticPlace;
  open: boolean;
  onToggle: VoidFunction;
}

export const TogglePanel: FC<TogglePanelProps> = ({
  title,
  toggleContent,
  isActive,
  subTitle,
  startingHeight,
  accessibilityLabel,
  analyticsPlace,
  open,
  onToggle,
}) => {
  const animatedValue = useAnimatedValue(0);
  const isFocused = useIsFocused();
  const appTheme = useAppTheme();
  const { height: screenHeight } = useWindowDimensions();
  const { trackButtonClick } = useAnalytics();

  const [modalTop, setModalTop] = useState(0);

  const [show] = useDebounce(isFocused && open, DEBOUNCE_MS);

  useEffect(() => {
    Animated.timing(animatedValue, {
      duration: 600,
      easing: Easing.elastic(0.7),
      toValue: show ? 1 : 0,
      useNativeDriver: false,
    }).start();
  }, [show, animatedValue]);

  const handlePress = useCallback(() => {
    onToggle();
    trackButtonClick(accessibilityLabel, analyticsPlace);
  }, [onToggle, accessibilityLabel, analyticsPlace]);

  const rotate = useMemo(
    () =>
      animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '180deg'],
      }),
    [],
  );

  const height = useMemo(
    () =>
      animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: [startingHeight, screenHeight - modalTop],
      }),
    [startingHeight, modalTop, screenHeight],
  );
  const opacity = useMemo(
    () =>
      animatedValue.interpolate({
        inputRange: [0, 0.7, 1],
        outputRange: [0, 0, 1],
      }),
    [],
  );
  const containerRef = useRef<View>(null);

  return (
    <styled.Container
      ref={containerRef}
      onLayout={() => {
        if (containerRef.current) {
          containerRef.current.measure((_x, _y, _width, height, _pageX, pageY) => {
            setModalTop(height + pageY - (StatusBar.currentHeight ?? 0));
          });
        }
      }}
    >
      <styled.Header
        activeOpacity={0.5}
        onPress={handlePress}
        testID={accessibilityLabel}
        accessible={false}
        accessibilityLabel={accessibilityLabel}
        disabled={!isActive}
      >
        {title}
        <Animated.View style={{ transform: [{ rotate }] }}>
          <ChevronDownIcon color={colors.white} />
        </Animated.View>
      </styled.Header>
      {subTitle}
      <Modal
        visible={show}
        transparent
        animationType="none"
        style={{ flexDirection: 'column' }}
        onRequestClose={handlePress}
      >
        <TouchableWithoutFeedback onPress={handlePress}>
          <styled.DialogOverlay />
        </TouchableWithoutFeedback>
        <styled.ToggleContent
          style={{
            height,
            top: modalTop,
            backgroundColor: appTheme.main.color,
            ...Platform.select({
              web: {
                alignSelf: 'center',
                width: '100%',
                maxWidth: MAX_CONTENT_WIDTH,
              },
            }),
          }}
        >
          <AnimatedOpacityContainer style={{ opacity }}>{toggleContent}</AnimatedOpacityContainer>
        </styled.ToggleContent>
      </Modal>
    </styled.Container>
  );
};
