import { Auth } from '@aws-amplify/auth';

import { SignOutOptions, UserAttributes } from './types';

export const isUserAuthenticated = async (): Promise<boolean> => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const userEmail = user.attributes.email;
    const userSubId = user.attributes.sub;
    console.log('Authenticated user', {
      email: userEmail,
      subscription: userSubId,
      jwt: user.signInUserSession.idToken.jwtToken,
    });
    return !!user && !!userEmail && !!userSubId;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const signUp = async (email: string, password: string): Promise<void> => {
  try {
    const attributes = {
      email,
    };
    const success = await Auth.signUp({
      username: email,
      password: password,
      attributes,
    });
    console.log('User successfully signed up: ', success);
  } catch (error) {
    console.error('Could not sign up: ', error);
    throw error;
  }
};

export const confirmSignUp = async (email: string, code: string): Promise<void> => {
  try {
    await Auth.confirmSignUp(email, code);
    console.log('Verification code confirmed');
  } catch (error) {
    console.error('Invalid code. Please enter a valid verification code: ', error);
    throw error;
  }
};

export const resendCode = async (email: string): Promise<void> => {
  try {
    await Auth.resendSignUp(email);
  } catch (error) {
    console.error('Error resending verification code: ', error);
    throw error;
  }
};

export const sendCode = async (email: string): Promise<void> =>
  Auth.forgotPassword(email).catch(error => {
    console.error('Could not send verification code', error);
    throw error;
  });

export const resetPassword = (email: string, code: string, newPassword: string) =>
  Auth.forgotPasswordSubmit(email, code, newPassword).catch(error => {
    console.error('Could not reset password', error);
    throw error;
  });

export const signIn = async (email: string, password: string) =>
  Auth.signIn(email, password)
    .then(() => {
      console.log('Signed in');
    })
    .catch(error => {
      console.error('Could not sign in', error);
      throw error;
    });

export const signOut = async (options?: SignOutOptions) =>
  Auth.signOut(options)
    .then(() => {
      console.log('Signed out');
    })
    .catch(error => {
      console.error('Could not sign out', error);
      throw error;
    });

export const deleteAccount = async () =>
  Auth.deleteUser()
    .then(() => {
      console.log('User has been successfully deleted');
    })
    .catch(error => {
      console.error('Could not delete user', error);
      throw error;
    });

export const getIdJwt = async () =>
  Auth.currentSession()
    .then(session => session.getIdToken().getJwtToken())
    .catch(error => {
      console.error('Could not retrieve JWT token', error);
      throw error;
    });

export const getUserId = () =>
  getUserAttributes()
    .then(attributes => attributes.userId)
    .catch(error => {
      throw error;
    });

export const getEmail = () =>
  getUserAttributes()
    .then(attributes => attributes.email)
    .catch(error => {
      throw error;
    });

const getUserAttributes = async (): Promise<UserAttributes> =>
  Auth.currentUserInfo()
    .then(async userInfo => {
      const rawAttributes = userInfo?.attributes;
      return {
        userId: rawAttributes?.sub || '',
        email: rawAttributes?.email || '',
      };
    })
    .catch(error => {
      console.error('Could not fetch user attributes', error);
      throw error;
    });
