import { TelematicaSimulator, VehicleManufacturer } from '@hiven-energy/hiven-client';
import { useQueryClient } from '@tanstack/react-query';
import * as Linking from 'expo-linking';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import Loader from 'src/components/Loader/Loader';
import { useAppState } from 'src/hooks/useAppState';
import { useConnectSuccessTarget } from 'src/hooks/useConnectSuccessTarget';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useDevices } from 'src/queries/sdk';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { useHivenClient } from 'src/services/hiven';
import { Mode, useMode } from 'src/store/mode';
import { dismissBrowser, openBrowser } from 'src/utils/browser';

import Error from './Error/Error';
import Success from './Success/Success';

const DEVICES_MAX_REFETCHES = 10;
const DEVICES_REFETCH_INTERVAL = 2000;

type Manufacturer = VehicleManufacturer | TelematicaSimulator;

enum ConnectState {
  INITIAL = 'INITIAL',
  CONNECT = 'CONNECT',
  CANCEL = 'CANCEL',
  CONNECTED = 'CONNECTED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface RoutedProps {
  manufacturer?: Manufacturer;
  // Telematica callback params
  deviceType?: string;
  devices?: string;
}

type Props = ScreenProps<RouteId.TelematicaConnect>;

const TelematicaConnect: FC<Props> = ({ route, navigation }) => {
  const { manufacturer, deviceType, devices } = route.params || {};

  const newDeviceExternalId = useMemo(() => devices?.split(',')[0], [devices]);

  const intl = useIntl();
  const toast = useToast();
  const appState = useAppState();
  const queryClient = useQueryClient();

  const [mode] = useMode();
  const hivenClient = useHivenClient();
  const successTarget = useConnectSuccessTarget();

  useAnalyticsTimeEvent(MixpanelEvents.VEHICLE_ADDED);

  const [refetches, setRefetches] = useState(0);
  const [connectState, setConnectState] = useState(ConnectState.INITIAL);

  const devicesQuery = useDevices({
    onSuccess: () => {
      setRefetches(refetches => refetches + 1);
    },
    refetchInterval: DEVICES_REFETCH_INTERVAL,
    enabled: connectState === ConnectState.CONNECTED && refetches <= DEVICES_MAX_REFETCHES,
  });

  useEffect(() => {
    if (manufacturer) {
      setConnectState(ConnectState.CONNECT);
    } else if (deviceType && devices) {
      setConnectState(ConnectState.CONNECTED);
    } else {
      setConnectState(ConnectState.FAILURE);
    }
  }, [manufacturer, deviceType, devices]);

  useEffect(() => {
    if (Platform.OS === 'android' && appState === 'active' && connectState === ConnectState.CONNECT) {
      setConnectState(ConnectState.CANCEL);
    }
  }, [appState, connectState]);

  useEffect(() => {
    if (connectState === ConnectState.CANCEL) {
      navigation.navigate(RouteId.VehicleBrands);
    }
  }, [connectState]);

  useEffect(() => {
    if (connectState !== ConnectState.CONNECT) {
      dismissBrowser();
    }
  }, [connectState]);

  useEffect(() => {
    if (connectState === ConnectState.CONNECT) {
      performConnect(manufacturer!);
    }
  }, [connectState]);

  useEffect(() => {
    const device = devicesQuery.data?.find(device => device.attributes.externalId === newDeviceExternalId);
    if (device) {
      setConnectState(ConnectState.SUCCESS);
      return;
    }
    if (refetches > DEVICES_MAX_REFETCHES) {
      setConnectState(ConnectState.FAILURE);
    }
  }, [devicesQuery.data, newDeviceExternalId, refetches]);

  const performConnect = async (brand: Manufacturer) => {
    successTarget.saveTarget();
    let connectUrl: string;
    try {
      connectUrl = await getConnectUrl(brand);
    } catch (_error) {
      toast.show(intl.formatMessage({ id: 'TelematicaConnect.authorizationUrl.fetch.error' }), {
        type: 'danger',
      });
      setConnectState(ConnectState.FAILURE);
      return;
    }
    const result = await openBrowser(connectUrl);
    if (result?.type === 'cancel') {
      setConnectState(ConnectState.CANCEL);
    }
  };

  const getConnectUrl = async (manufacturer: Manufacturer) => {
    const redirectUrl = Linking.createURL(RouteId.TelematicaConnect);
    const { authorizationUrl } = await hivenClient.getTelematicaAuthorizationUrl(
      manufacturer,
      redirectUrl,
      mode !== Mode.LIVE,
    );
    return decodeURIComponent(authorizationUrl);
  };

  const handleContinuePress = () => {
    queryClient.invalidateQueries(['devices']);
    navigation.replace(...successTarget.getTarget());
  };

  const handleTryAgainPress = () => {
    navigation.navigate(RouteId.VehicleBrands);
  };

  const handleCancelPress = () => {
    navigation.navigate(RouteId.Initial);
  };

  const analytics = useMemo(() => ({ Devices: devices, DeviceType: deviceType }), [devices, deviceType]);

  if (connectState === ConnectState.SUCCESS) {
    return <Success onContinue={handleContinuePress} analytics={analytics} />;
  }

  if (connectState === ConnectState.FAILURE) {
    return <Error onTryAgain={handleTryAgainPress} onCancel={handleCancelPress} analytics={analytics} />;
  }

  return <Loader fullScreen />;
};

export default TelematicaConnect;
