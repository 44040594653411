import React, { FC, PropsWithChildren } from 'react';
// import order is important in this file
// possible errors if changed: https://github.com/expo/expo/issues/6536
import 'intl';
import 'intl/locale-data/jsonp/en';
import { IntlProvider } from 'react-intl';

import { EnMessages, FiMessages } from 'src/i18n';

import { Language } from './types';
import { getLanguage } from './utils';

const I18NProvider: FC<PropsWithChildren> = ({ children }) => {
  const language = getLanguage();
  return (
    <IntlProvider locale={language} messages={language === Language.FI ? FiMessages : EnMessages}>
      {children}
    </IntlProvider>
  );
};

export default I18NProvider;
