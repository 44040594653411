import { SessionState } from 'src/store/session';

export const sessionState: SessionState = {
  loggedIn: true,
  userId: '42a84fde-faa3-4c5f-89f8-6c33a63d0293',
  email: 'test@test.test',
  signIn: async () => null,
  signOut: async () => undefined,
  deleteAccount: async () => undefined,
  initialize: async () => null,
  getAuthorizationToken: async () => '',
};
