import { APP_ENV, PARTNER } from '@env';
import { ContainerStyle, Modal, Select, SelectOption, Typography } from '@hiven-energy/hiven-ui';
import * as Clipboard from 'expo-clipboard';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import React, { FC, useEffect } from 'react';
import { Platform } from 'react-native';

import { useNotifications } from 'src/notifications';
import { Mode, useMode } from 'src/store/mode';
import { MAX_CONTENT_WIDTH } from 'src/theme';

import * as styled from './styles';

interface Props {
  visible: boolean;
  onClose: VoidFunction;
}

const modes: SelectOption<Mode>[] = [
  {
    label: 'Live',
    value: Mode.LIVE,
  },
  {
    label: 'Simulated',
    value: Mode.SIMULATED,
  },
  {
    label: 'Test',
    value: Mode.TEST,
  },
  {
    label: 'Demo',
    value: Mode.DEMO,
  },
];

const DebugModal: FC<Props> = ({ visible, onClose }) => {
  const [mode, changeMode] = useMode();
  const notifications = useNotifications();

  useEffect(() => {
    notifications.requestToken();
  }, []);

  const copyToClipboard = (value: string) => {
    Clipboard.setStringAsync(value);
  };

  const deviceToken = notifications.token ?? '-';

  const modalRootStyle: ContainerStyle = { maxWidth: MAX_CONTENT_WIDTH };

  return (
    <Modal title="Debug Modal" visible={visible} onClose={onClose} styles={{ root: modalRootStyle }}>
      <Typography>{`Environment: ${APP_ENV}`}</Typography>
      <Typography>{`Partner: ${PARTNER}`}</Typography>
      <Typography>{`Mode: ${mode}`}</Typography>
      <styled.SelectWrapper>
        <Select value={mode} options={modes} onChange={changeMode} />
      </styled.SelectWrapper>
      <Typography>{`Version: ${Constants.expoConfig?.version}`}</Typography>
      <Typography>Update ID: {Updates.updateId?.substr(-12) ?? '-'}</Typography>
      <styled.Copy onPress={() => copyToClipboard(deviceToken)}>
        <Typography>Device Token: {deviceToken}</Typography>
      </styled.Copy>
      {Platform.OS === 'android' && (
        <>
          <Typography>{`Android Package: ${Constants.expoConfig?.android?.package}`}</Typography>
          <Typography>{`Android Build: ${Constants.expoConfig?.android?.versionCode}`}</Typography>
        </>
      )}
      {Platform.OS === 'ios' && (
        <>
          <Typography>{`iOS Package: ${Constants.expoConfig?.ios?.bundleIdentifier}`}</Typography>
          <Typography>{`iOS Build: ${Constants.expoConfig?.ios?.buildNumber}`}</Typography>
        </>
      )}
    </Modal>
  );
};

export default React.memo(DebugModal);
