import { UserLocation } from '@hiven-energy/hiven-client';

import { formatCountryName } from 'src/i18n';
import { Address } from 'src/services/maps/types';

export const formatAddress = (address: Address): string =>
  isFullAddress(address)
    ? `${address.streetAddress}, ${address.postalCode} ${address.city}, ${formatCountryName(address.countryCode)}`
    : '';

export const isFullAddress = (address: Address): address is Required<Address> =>
  address.streetAddress !== undefined &&
  address.city !== undefined &&
  address.postalCode !== undefined &&
  address.countryCode !== undefined;

export const createAddress = (userLocation: UserLocation): Required<Address> => ({
  streetAddress: userLocation.streetAddress,
  city: userLocation.city,
  postalCode: userLocation.postalCode,
  countryCode: userLocation.countryCode,
});

export const isSameAddress = (address1: Address, address2: Address): boolean =>
  address1.streetAddress === address2.streetAddress &&
  address1.city === address2.city &&
  address1.postalCode === address2.postalCode &&
  address1.countryCode === address2.countryCode;
