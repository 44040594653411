import {
  ChargerPreferences,
  EnergyUnit,
  SetUserLocationPayload,
  TariffInfo,
  Unit,
  UserLocation,
  VehicleChargingBehavior,
  VehiclePreferences,
  VehicleStatus,
} from '@hiven-energy/hiven-client';

import { ReadyTimeByType } from 'src/containers/ready-time/types';
import {
  createReadyTimeByType,
  defaultReadyTime,
  isDaysOfWeekSpecificReadyTime,
} from 'src/containers/ready-time/utils';
import { createAddress } from 'src/utils/address';

import { initialChargeLevels, initialPreferences } from './constants';
import { ChargeLevels, ChargingLocation, Preferences, SetupType } from './types';

export const arePreferencesSet = <T extends ChargerPreferences>(preferences: T): preferences is Required<T> => {
  const {
    locationId,
    chargingBehavior,
    minimumEnergyTransfer,
    targetSoC,
    targetEnergyTransfer,
    minimalSoC,
    readyTime,
    weekDaySpecificReadyTimes,
    associatedDeviceId,
  } = preferences;

  const isLocationSet = !!locationId;
  const isReadyTimeSet = readyTime !== undefined || isDaysOfWeekSpecificReadyTime(weekDaySpecificReadyTimes);

  const areChargingParametersSet = [
    associatedDeviceId ? targetSoC : targetEnergyTransfer,
    associatedDeviceId ? minimalSoC : minimumEnergyTransfer,
    chargingBehavior,
  ].every(parameter => parameter !== undefined);

  return isLocationSet && areChargingParametersSet && isReadyTimeSet;
};

export const isTariffInfoSet = (tariffInfo: TariffInfo | undefined): tariffInfo is Required<TariffInfo> => {
  return !!(tariffInfo && tariffInfo.meterId && tariffInfo.tariff && tariffInfo.electricalUtilityProvider);
};

export const parsePreferences = (
  chargerPreferences: ChargerPreferences | undefined,
  chargingLocation: UserLocation | undefined,
): Preferences => ({
  ...initialPreferences,
  setupType: parseSetupType(chargerPreferences),
  chargingLocation: parseChargingLocation(chargingLocation),
  associatedVehicleId: chargerPreferences?.associatedDeviceId,
  readyTime: (chargerPreferences && createReadyTimeByType(chargerPreferences)) || initialPreferences.readyTime,
  chargeLevels: parseChargeLevels(chargerPreferences),
  chargingBehavior: chargerPreferences?.chargingBehavior ?? initialPreferences.chargingBehavior,
});

const parseSetupType = (chargerPreferences: ChargerPreferences | undefined): SetupType => {
  if (!chargerPreferences) return SetupType.STANDALONE;
  return chargerPreferences.associatedDeviceId ? SetupType.ASSOCIATED : SetupType.STANDALONE;
};

export const parseChargingLocation = (chargingLocation: UserLocation | undefined): ChargingLocation => ({
  address: chargingLocation ? createAddress(chargingLocation) : initialPreferences.chargingLocation.address,
  region: chargingLocation?.coordinates ?? initialPreferences.chargingLocation.region,
  name: chargingLocation?.name ?? '',
  tariffInfo: chargingLocation?.tariffInfo,
  chargingLocationId: chargingLocation?.id,
});

export const parseBatteryCapacity = (
  vehicleStatus: VehicleStatus | undefined,
  vehiclePreferences: VehiclePreferences | undefined,
) => vehicleStatus?.batteryCapacity || vehiclePreferences?.batteryCapacity || initialPreferences.batteryCapacity;

const parseChargeLevels = (chargerPreferences: ChargerPreferences | undefined): ChargeLevels => {
  const setupType = parseSetupType(chargerPreferences);
  return setupType === SetupType.STANDALONE
    ? parseStandaloneChargeLevels(chargerPreferences)
    : parseAssociatedChargeLevels(chargerPreferences);
};

const parseStandaloneChargeLevels = (chargerPreferences: ChargerPreferences | undefined): ChargeLevels => {
  if (chargerPreferences?.minimumEnergyTransfer && chargerPreferences.targetEnergyTransfer) {
    return {
      minimum: chargerPreferences.minimumEnergyTransfer.value,
      maximum: chargerPreferences.targetEnergyTransfer.value,
    };
  }
  return initialChargeLevels[SetupType.STANDALONE];
};

const parseAssociatedChargeLevels = (chargerPreferences: ChargerPreferences | undefined): ChargeLevels => {
  if (chargerPreferences?.minimalSoC && chargerPreferences.targetSoC) {
    return {
      minimum: chargerPreferences.minimalSoC.value,
      maximum: chargerPreferences.targetSoC.value,
    };
  }
  return initialChargeLevels[SetupType.ASSOCIATED];
};

export const createChargerPreferences = (preferences: Preferences, locationId: string): ChargerPreferences =>
  preferences.setupType === SetupType.STANDALONE
    ? createStandaloneChargerPreferences(preferences, locationId)
    : createAssociatedChargerPreferences(preferences, locationId);

const createStandaloneChargerPreferences = (preferences: Preferences, locationId: string): ChargerPreferences => ({
  chargingBehavior: preferences.chargingBehavior,
  ...convertToPreferencesReadyTime(preferences.readyTime),
  locationId,
  associatedDeviceId: 'DISSOCIATE',
  minimumEnergyTransfer: {
    value: preferences.chargeLevels.minimum,
    unit: EnergyUnit.KWH,
  },
  targetEnergyTransfer: {
    value: preferences.chargeLevels.maximum,
    unit: EnergyUnit.KWH,
  },
});

const createAssociatedChargerPreferences = (preferences: Preferences, locationId: string): ChargerPreferences => ({
  chargingBehavior: preferences.chargingBehavior,
  ...convertToPreferencesReadyTime(preferences.readyTime),
  locationId,
  associatedDeviceId: preferences.associatedVehicleId,
  minimalSoC: {
    value: preferences.chargeLevels.minimum,
    unit: Unit.PERCENT,
  },
  targetSoC: {
    value: preferences.chargeLevels.maximum,
    unit: Unit.PERCENT,
  },
});

const convertToPreferencesReadyTime = (
  readyTime: ReadyTimeByType,
): Pick<ChargerPreferences, 'readyTime' | 'weekDaySpecificReadyTimes'> => {
  return {
    readyTime: readyTime.type === 'all_day' ? readyTime.time : defaultReadyTime,
    weekDaySpecificReadyTimes: readyTime.type === 'days_of_week' ? readyTime.time : {},
  };
};

export const createVehiclePreferences = (preferences: Preferences): VehiclePreferences | undefined => {
  if (preferences.setupType !== SetupType.ASSOCIATED) return undefined;
  /*
            When associating EV with Charger - BE automatically switches charging behavior to "SMART_CHARGE_OFF"
            But this happens asynchronously, so we also make sure to send "SMART_CHARGE_OFF" on FE side
          */
  return { chargingBehavior: VehicleChargingBehavior.SMART_CHARGE_OFF, batteryCapacity: preferences.batteryCapacity };
};

export const createUserLocationPayload = (preferences: Preferences): SetUserLocationPayload => ({
  ...preferences.chargingLocation.address!,
  coordinates: preferences.chargingLocation.region,
  name: preferences.chargingLocation.name,
  tariffInfo: preferences.chargingLocation.tariffInfo,
});

export const validateSaveConditions = (preferences: Preferences) =>
  !!(preferences.chargingLocation.address && (preferences.associatedVehicleId ? preferences.batteryCapacity : true));
