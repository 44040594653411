import { TELEMATICA_SIMULATOR, VehicleIntegration, VehicleManufacturer } from '@hiven-energy/hiven-client';

import { Brand } from './types';

export const brands: Brand[] = [
  {
    name: 'Audi',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.AUDI,
  },
  {
    name: 'BMW',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.BMW,
  },
  {
    name: 'BMW - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.BMW,
  },
  {
    name: 'Citroen',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.CITROEN,
  },
  {
    name: 'Citroen - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.CITROEN,
  },
  {
    name: 'Cupra',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.CUPRA,
  },
  {
    name: 'Cupra - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.CUPRA,
  },
  {
    name: 'DS',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.DS,
  },
  {
    name: 'DS - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.DS,
  },
  {
    name: 'Fiat',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.FIAT,
  },
  {
    name: 'Ford',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.FORD,
  },
  {
    name: 'Ford - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.FORD,
  },
  {
    name: 'Hyundai',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.HYUNDAI,
  },
  {
    name: 'Hyundai - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.HYUNDAI,
  },
  {
    name: 'Jaguar',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.JAGUAR,
  },
  {
    name: 'Jaguar - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.JAGUAR,
  },
  {
    name: 'Jeep',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.JEEP,
  },
  {
    name: 'Kia',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.KIA,
  },
  {
    name: 'Kia - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.KIA,
  },
  {
    name: 'Land Rover',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.LAND_ROVER,
  },
  {
    name: 'Land Rover - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.LAND_ROVER,
  },
  {
    name: 'Mercedes',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.MERCEDES,
  },
  {
    name: 'Mini',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.MINI,
  },
  {
    name: 'Mini - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.MINI,
  },
  {
    name: 'Nissan',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.NISSAN,
  },
  {
    name: 'Opel',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.OPEL,
  },
  {
    name: 'Peugeot',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.PEUGEOT,
  },
  {
    name: 'Porsche',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.PORSCHE,
  },
  {
    name: 'Renault',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.RENAULT,
  },
  {
    name: 'Seat',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.SEAT,
  },
  {
    name: 'Skoda',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.SKODA,
  },
  {
    name: 'Skoda - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.SKODA,
  },
  {
    name: 'Smart',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.SMART,
  },
  {
    name: 'Tesla',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.TESLA,
  },
  {
    name: 'Tesla - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    manufacturer: VehicleManufacturer.TESLA,
  },
  {
    name: 'Toyota',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.TOYOTA,
  },
  {
    name: 'Volkswagen',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.VOLKSWAGEN,
  },
  {
    name: 'Volkswagen - SmartCar',
    integration: VehicleIntegration.SMARTCAR,
    // TODO: Remove this workaround when we get rid of Telematica integration
    manufacturer: 'VOLKSWAGEN' as unknown as VehicleManufacturer,
  },
  {
    name: 'Volvo',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: VehicleManufacturer.VOLVO,
  },
  {
    name: 'Simulator',
    integration: VehicleIntegration.TELEMATICA,
    manufacturer: TELEMATICA_SIMULATOR,
  },
];
