import { Button, EmailIcon, Link, LockedIcon } from '@hiven-energy/hiven-ui';
import { StatusBar } from 'expo-status-bar';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import { useA11y } from 'src/a11y';
import { TextField } from 'src/components/form-fields/TextField';
import { useFormUserPasswordResolver } from 'src/hooks/useFormUserPasswordResolver';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';
import { CognitoAction } from 'src/services/session';
import { useSession } from 'src/store/session';

import { getCognitoErrorMessageId } from '../utils';

import { signInDefaultValues } from './functions';
import * as styled from './styles';
import { SignInFormData } from './types';

type Props = ScreenProps<RouteId.SignIn>;

const SignIn: FC<Props> = ({ route, navigation }) => {
  const intl = useIntl();
  const a11y = useA11y();
  const toast = useToast();
  const session = useSession();

  const [loading, setLoading] = useState(false);
  const resolver = useFormUserPasswordResolver(true);
  const { trackButtonClick, trackLoggedIn } = useAnalytics();

  const { control, handleSubmit, getValues, setValue } = useForm<SignInFormData>({
    mode: 'onBlur',
    defaultValues: signInDefaultValues(route.params),
    resolver,
  });

  useEffect(() => {
    setValue('email', route.params?.email || '');
  }, [route.params?.email]);

  const handleSignIn = (data: SignInFormData) => {
    setLoading(true);
    session
      .signIn(data.email, data.password)
      .then(user => {
        if (user) {
          trackLoggedIn('SignIn.button', user.email);
        }
      })
      .catch(error => {
        const errorMessageId = getCognitoErrorMessageId(CognitoAction.SIGN_IN, error);
        if (errorMessageId.includes('UserNotConfirmedException')) {
          navigation.navigate(RouteId.ConfirmSignUp, { email: getValues().email, sendCode: true });
          return;
        }
        toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleForgetPasswordPress = () => {
    trackButtonClick('SignIn.forgottenPwdLink');
    navigation.navigate(RouteId.ResetPassword, { email: getValues().email });
  };

  const handleSignUpPress = () => {
    trackButtonClick('SignIn.signUpLink');
    navigation.navigate(RouteId.SignUp, { email: getValues().email });
  };

  return (
    <styled.Container>
      <styled.Title variant="h3" accessibilityLabel={a11y.formatLabel('SignIn.description')}>
        <FormattedMessage id="SignIn.title" />
      </styled.Title>
      <TextField
        placeholder={intl.formatMessage({ id: 'common.email' })}
        control={control}
        name="email"
        leftIcon={EmailIcon}
      />
      <TextField
        placeholder={intl.formatMessage({ id: 'common.password' })}
        control={control}
        name="password"
        leftIcon={LockedIcon}
        password
      />
      <styled.LinkContainer>
        <Link onPress={handleForgetPasswordPress} accessibilityLabel={a11y.formatLabel('SignIn.forgottenPwdLink')}>
          <FormattedMessage id="SignIn.resetPasswordLink" />
        </Link>
      </styled.LinkContainer>
      <styled.Footer>
        <Button
          accessible
          disabled={loading}
          loading={loading}
          title={intl.formatMessage({ id: 'SignIn.button' })}
          onPress={handleSubmit(handleSignIn)}
          testID={a11y.formatLabel('SignIn.button')}
        />
        <styled.SignUpQuestion>
          <styled.SignUpText accessibilityLabel={a11y.formatLabel('SignIn.signUpQn')}>
            <FormattedMessage id="SignIn.signUpQuestion" />
          </styled.SignUpText>
          <Link onPress={handleSignUpPress} accessibilityLabel={a11y.formatLabel('SignIn.signUpLink')}>
            <FormattedMessage id="SignIn.signUpLink" />
          </Link>
        </styled.SignUpQuestion>
      </styled.Footer>
      <StatusBar style="light" />
    </styled.Container>
  );
};

export default SignIn;
