import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { z } from 'zod';

import { email, password } from 'src/utils/validationRules';

export const useFormUserPasswordResolver = (disableStrengthValidation = false) => {
  const intl = useIntl();
  return useMemo(
    () =>
      zodResolver(
        z.object({
          password: password(intl, disableStrengthValidation),
          email: email(intl),
        }),
      ),
    [intl, disableStrengthValidation],
  );
};
