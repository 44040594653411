import { InputField } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { useAnalytics } from 'src/services/analytics';
import { notEmpty } from 'src/utils/validationRules';

import FormField from '../FormField';

import { CodeControlType } from './types';

interface Props {
  control: CodeControlType;
}

const CodeField: FC<Props> = ({ control }) => {
  const intl = useIntl();
  const { trackInputValue } = useAnalytics();
  const a11y = useA11y();
  const accessibilityLabel = a11y.formatLabel('common.code');

  return (
    <Controller
      control={control}
      rules={notEmpty(intl)}
      render={({ field: { value, onChange, name, onBlur } }) => {
        const handleBlur = () => {
          trackInputValue(name);
          onBlur();
        };

        return (
          <FormField>
            <InputField
              value={value}
              onChange={onChange}
              onBlur={handleBlur}
              testID={accessibilityLabel}
              accessibilityLabel={accessibilityLabel}
              placeholder={intl.formatMessage({ id: 'common.code' })}
              autoCapitalize="none"
            />
          </FormField>
        );
      }}
      name="code"
      defaultValue=""
    />
  );
};

export default CodeField;
