import { ChargerPreferences, TariffInfo, VehiclePreferences } from '@hiven-energy/hiven-client';
import { useRoute } from '@react-navigation/native';
import { useMemo } from 'react';

import { Analytics } from './analytics';
import { AnalyticPlace } from './types';

export const useAnalytics = () => {
  const { name: routeName } = useRoute();

  return useMemo(
    () => ({
      trackSliderChange: (name: string, value: string, place?: AnalyticPlace) => {
        Analytics.userInteracted({
          PageName: routeName,
          Place: place,
          ClickSource: 'Slider',
          Name: name,
          Value: value,
        });
      },
      trackButtonClick: (clickText: string, place?: AnalyticPlace) => {
        Analytics.userInteracted({
          PageName: routeName,
          ClickText: clickText,
          Place: place,
          ClickSource: 'Button',
        });
      },
      trackInputValue: (inputName: string, value?: string, place?: AnalyticPlace) => {
        Analytics.userInteracted({
          Name: inputName,
          PageName: routeName,
          Place: place,
          Value: value,
          ClickSource: 'Input',
        });
      },
      trackRefresh: (place?: AnalyticPlace) => {
        Analytics.userInteracted({ ClickSource: 'Refresh gesture', PageName: routeName, Place: place });
      },
      trackOnboard: (step: number, completed: boolean, place?: AnalyticPlace) => {
        Analytics.userOnboard(step, completed, {
          PageName: routeName,
          Place: place,
          ClickSource: 'Swipe gesture',
        });
      },
      trackUserDataChange: (clickText: string, tariffInfo: Partial<TariffInfo>, place?: AnalyticPlace) => {
        Analytics.saveTariffInfo(tariffInfo, {
          PageName: routeName,
          ClickText: clickText,
          Place: place,
          ClickSource: 'Button',
        });
      },
      trackChargerPreferencesChange: (preferences: ChargerPreferences, deviceId: string, place?: AnalyticPlace) => {
        Analytics.saveChargerPreferences(preferences, deviceId, {
          PageName: routeName,
          Place: place,
        });
      },
      trackVehiclePreferencesChange: (preferences: VehiclePreferences, deviceId: string, place?: AnalyticPlace) => {
        Analytics.saveVehiclePreferences(preferences, deviceId, {
          Place: place,
          PageName: routeName,
        });
      },
      trackDeleteDevice: (clickText: string, deviceId: string, place?: AnalyticPlace) => {
        Analytics.deleteDevice(deviceId, {
          ClickSource: 'Button',
          ClickText: clickText,
          PageName: routeName,
          Place: place,
        });
      },
      trackDeleteAccount: (clickText: string, place?: AnalyticPlace) => {
        Analytics.deleteAccount({
          PageName: routeName,
          ClickText: clickText,
          Place: place,
          ClickSource: 'Button',
        });
      },
      trackRegisterWallboxAccount: (clickText: string, email: string, place?: AnalyticPlace) => {
        Analytics.registerWallboxAccount(email, {
          ClickSource: 'Button',
          ClickText: clickText,
          Place: place,
          PageName: routeName,
        });
      },
      trackLoggedIn: (clickText: string, email: string, place?: AnalyticPlace) => {
        void Analytics.loggedIn(email, {
          ClickSource: 'Button',
          ClickText: clickText,
          Place: place,
          PageName: routeName,
        });
      },
      trackRegisterCharger: (clickText: string, name: string, place?: AnalyticPlace) => {
        Analytics.registerCharger(name, {
          PageName: routeName,
          Place: place,
          ClickText: clickText,
          ClickSource: 'Button',
        });
      },
      trackConfirmSignUp: (clickText: string, email: string, place?: AnalyticPlace) => {
        Analytics.confirmSignUp(email, {
          ClickSource: 'Button',
          ClickText: clickText,
          Place: place,
          PageName: routeName,
        });
      },
      trackSignUp: (clickText: string, email: string, place?: AnalyticPlace) => {
        Analytics.signUp(email, {
          ClickSource: 'Button',
          ClickText: clickText,
          Place: place,
          PageName: routeName,
        });
      },
      trackLoggedOut: (clickText: string, place?: AnalyticPlace) => {
        Analytics.loggedOut({ ClickSource: 'Button', PageName: routeName, Place: place, ClickText: clickText });
      },
      trackVehicleAdded: (
        clickText: string,
        deviceType: string | undefined,
        devices: string | undefined,
        place?: AnalyticPlace,
      ) => {
        Analytics.vehicleAdded(deviceType, devices, {
          ClickSource: 'Button',
          ClickText: clickText,
          Place: place,
          PageName: routeName,
        });
      },
      trackRetryVehicleConnect: (
        clickText: string,
        deviceType: string | undefined,
        devices: string | undefined,
        place?: AnalyticPlace,
      ) => {
        Analytics.retryVehicleConnect(deviceType, devices, {
          ClickSource: 'Button',
          ClickText: clickText,
          Place: place,
          PageName: routeName,
        });
      },
      trackRetryVehicleConnectCancel: (
        clickText: string,
        deviceType: string | undefined,
        devices: string | undefined,
        place?: AnalyticPlace,
      ) => {
        Analytics.retryVehicleConnectCancel(deviceType, devices, {
          ClickSource: 'Button',
          ClickText: clickText,
          Place: place,
          PageName: routeName,
        });
      },
      trackVehicleAssociated: (deviceId: string, place?: AnalyticPlace) => {
        Analytics.vehicleAssociated(deviceId, {
          Place: place,
          PageName: routeName,
        });
      },
      trackUserLocationSaved: (
        {
          id,
          name,
        }: {
          id: string;
          name: string;
        },
        place?: AnalyticPlace,
      ) => {
        Analytics.userLocationSaved(id, name, {
          PageName: routeName,
          Place: place,
        });
      },
      trackUserWasWaitingForSchedule: (scheduleVisible: boolean, place?: AnalyticPlace) => {
        Analytics.userWasWaitingForSchedule(scheduleVisible, {
          PageName: routeName,
          Place: place,
        });
      },
    }),
    [routeName],
  );
};
