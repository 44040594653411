import { Container as _Container, spacings } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';

export const Container = BaseView;

export const Section = styled(_Container)`
  margin-bottom: ${spacings.xs}px;
`;
