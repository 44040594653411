import { ChargerManufacturer } from '@hiven-energy/hiven-client';
import { Button, EmailIcon, LockedIcon } from '@hiven-energy/hiven-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigation } from '@react-navigation/native';
import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';
import { z } from 'zod';

import { useA11y } from 'src/a11y';
import DeviceLogo from 'src/components/DeviceLogo/DeviceLogo';
import { TextField } from 'src/components/form-fields/TextField';
import { RouteId } from 'src/nav/types';
import { useRegisterWallboxAccount } from 'src/queries/sdk';
import { signInDefaultValues } from 'src/screens/authentication/SignIn/functions';
import { SignInFormData } from 'src/screens/authentication/SignIn/types';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { email as emailValidationRule } from 'src/utils/validationRules';

import * as styled from './styles';

const WallboxLogin: FC = () => {
  const a11y = useA11y();
  const intl = useIntl();

  const toast = useToast();

  useAnalyticsTimeEvent(MixpanelEvents.CHARGER_REGISTERED);
  const { trackRegisterWallboxAccount } = useAnalytics();

  const navigation = useNavigation();

  const registerWallboxAccountMutation = useRegisterWallboxAccount({
    onSuccess: () => {
      const message = intl.formatMessage({ id: 'WallboxLogin.success' });
      toast.show(message, { type: 'success' });
      navigation.navigate(RouteId.WallboxConnect);
    },
    onError: () => {
      const message = intl.formatMessage({ id: 'WallboxLogin.error' });
      toast.show(message, { type: 'danger' });
    },
  });
  const resolver = useMemo(
    () =>
      zodResolver(
        z.object({
          password: z.string({ required_error: intl.formatMessage({ id: 'validation.required' }) }),
          email: emailValidationRule(intl),
        }),
      ),
    [intl],
  );

  const { control, handleSubmit } = useForm<SignInFormData>({
    mode: 'onBlur',
    defaultValues: signInDefaultValues(),
    resolver,
  });

  const handleSignIn = (data: SignInFormData) => {
    trackRegisterWallboxAccount('WallboxLogin.submit', data.email);
    registerWallboxAccountMutation.mutate({
      username: data.email,
      password: data.password,
    });
  };

  return (
    <styled.Container>
      <styled.Icon>
        <DeviceLogo manufacturer={ChargerManufacturer.WALLBOX} long />
      </styled.Icon>
      <styled.Title variant="h3">
        <FormattedMessage id="WallboxLogin.title" />
      </styled.Title>
      <TextField
        placeholder={intl.formatMessage({ id: 'common.email' })}
        control={control}
        name="email"
        leftIcon={EmailIcon}
      />
      <TextField
        placeholder={intl.formatMessage({ id: 'common.password' })}
        control={control}
        name="password"
        leftIcon={LockedIcon}
        password
      />
      <styled.Footer>
        <Button
          disabled={registerWallboxAccountMutation.isLoading}
          loading={registerWallboxAccountMutation.isLoading}
          title={intl.formatMessage({ id: 'WallboxLogin.submit' })}
          onPress={handleSubmit(handleSignIn)}
          testID={a11y.formatLabel('WallboxLogin.submit')}
        />
      </styled.Footer>
    </styled.Container>
  );
};

export default React.memo(WallboxLogin);
