import { ConfirmSignUpFormData, ConfirmSignUpRoutedProps } from './types';

export const confirmSignUpDefaultValues = (routedParams: ConfirmSignUpRoutedProps) => {
  const emailFromParams = routedParams.email;

  const defaultValues: ConfirmSignUpFormData = {
    email: emailFromParams || '',
    code: '',
  };

  return defaultValues;
};
