import * as Localization from 'expo-localization';

import EnRegions from './displayNames/region.en.json';
import FiRegions from './displayNames/region.fi.json';
import EnMessages from './en.json';
import { Language } from './types';

const messageIds = Object.keys(EnMessages);

export type MessageId = keyof typeof EnMessages;

export interface MessageDescriptor {
  id: MessageId;
}

export const isMessageId = (id: string): id is MessageId => messageIds.includes(id);

export const getLanguage = (): Language => {
  const [language] = Localization.locale.split('-');
  return language === 'fi' ? Language.FI : Language.EN;
};

export const formatCountryName = (code: string): string | undefined => {
  const language = getLanguage();
  const codeToNameMap = language === Language.FI ? FiRegions : EnRegions;
  return codeToNameMap[code as keyof typeof EnRegions];
};
