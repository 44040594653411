import React, { FC } from 'react';
import { ScrollView } from 'react-native';

import PriceSchedule from 'src/containers/PriceSchedule/PriceSchedule';
import { RouteId, ScreenProps } from 'src/nav/types';

import * as styled from './styles';

type Props = ScreenProps<RouteId.ChargerDashboard>;

const Analytics: FC<Props> = ({ route }) => {
  const { deviceId } = route.params;

  return (
    <styled.Container>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <PriceSchedule deviceId={deviceId} analyticsPlace="ChargerAnalytics.priceSchedule" />
      </ScrollView>
    </styled.Container>
  );
};

export default Analytics;
