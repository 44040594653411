import {
  Audi,
  Bmw,
  Citroen,
  Cupra,
  Ds,
  Fiat,
  Ford,
  Hyundai,
  Jaguar,
  Jeep,
  Kia,
  LandRover,
  Mercedes,
  Mini,
  Nissan,
  Opel,
  Peugeot,
  Porsche,
  Renault,
  Seat,
  Skoda,
  Smart,
  Tesla,
  Toyota,
  Volkswagen,
  Volvo,
  Wallbox,
  WallboxLong,
} from '@hiven-energy/hiven-brands';
import {
  ChargerManufacturer,
  TELEMATICA_SIMULATOR,
  TelematicaSimulator,
  VehicleManufacturer,
} from '@hiven-energy/hiven-client';
import { FC } from 'react';
import { SvgProps } from 'react-native-svg';

export type Manufacturer = ChargerManufacturer | VehicleManufacturer | TelematicaSimulator | 'VOLKSWAGEN';

export type SvgLogo = FC<SvgProps>;

export const manufacturerToLogoMap: Record<Manufacturer, SvgLogo | { default: SvgLogo; long: SvgLogo }> = {
  [VehicleManufacturer.AUDI]: Audi,
  [VehicleManufacturer.BMW]: Bmw,
  [VehicleManufacturer.CITROEN]: Citroen,
  [VehicleManufacturer.CUPRA]: Cupra,
  [VehicleManufacturer.DS]: Ds,
  [VehicleManufacturer.FIAT]: Fiat,
  [VehicleManufacturer.FORD]: Ford,
  [VehicleManufacturer.HYUNDAI]: Hyundai,
  [VehicleManufacturer.JAGUAR]: Jaguar,
  [VehicleManufacturer.JEEP]: Jeep, // TODO: Replace logo with the one provided by DT
  [VehicleManufacturer.KIA]: Kia,
  [VehicleManufacturer.LAND_ROVER]: LandRover,
  [VehicleManufacturer.MERCEDES]: Mercedes,
  [VehicleManufacturer.MINI]: Mini,
  [VehicleManufacturer.NISSAN]: Nissan,
  [VehicleManufacturer.OPEL]: Opel,
  [VehicleManufacturer.PEUGEOT]: Peugeot,
  [VehicleManufacturer.PORSCHE]: Porsche,
  [VehicleManufacturer.RENAULT]: Renault,
  [VehicleManufacturer.SEAT]: Seat,
  [VehicleManufacturer.SKODA]: Skoda,
  [VehicleManufacturer.SMART]: Smart, // TODO: Replace logo with the one provided by DT
  [VehicleManufacturer.TESLA]: Tesla,
  [VehicleManufacturer.TOYOTA]: Toyota,
  [VehicleManufacturer.VOLKSWAGEN]: Volkswagen,
  VOLKSWAGEN: Volkswagen,
  [VehicleManufacturer.VOLVO]: Volvo,
  [TELEMATICA_SIMULATOR]: Tesla,
  [ChargerManufacturer.WALLBOX]: { default: Wallbox, long: WallboxLong },
};
