import React, { FC } from 'react';

import { Manufacturer, manufacturerToLogoMap, SvgLogo } from './constants';

const LONG_LOGO_WIDTH = 143;
const LONG_LOGO_HEIGHT = 33;

export interface Props {
  manufacturer: Manufacturer;
  width?: number;
  height?: number;
  long?: boolean;
  color?: string;
  customProps?: React.ComponentProps<SvgLogo>;
}

const DeviceLogo: FC<Props> = ({ manufacturer, width = 44, height = 44, long, color, customProps }) => {
  const logo = manufacturerToLogoMap[manufacturer];
  const component = typeof logo === 'object' ? (long ? logo.long : logo.default) : logo;
  return React.createElement(component, {
    width: long ? LONG_LOGO_WIDTH : width,
    height: long ? LONG_LOGO_HEIGHT : height,
    fill: color,
    ...customProps,
  });
};

export default React.memo(DeviceLogo);
