import React, { FC, PropsWithChildren, useContext, useState } from 'react';

import { Analytics } from 'src/services/analytics';
import { SignOutOptions, useSessionService } from 'src/services/session';
import batchedUpdates from 'src/utils/batchedUpdates';

export interface SessionState {
  loggedIn: boolean;
  userId: string;
  email: string;
  signIn: (email: string, password: string) => Promise<{ userId: string; email: string } | null>;
  signOut: () => Promise<void>;
  deleteAccount: () => Promise<void>;
  initialize: () => Promise<{ userId: string; email: string } | null>;
  getAuthorizationToken: () => Promise<string>;
}

const initialSession: SessionState = {
  loggedIn: false,
  userId: '',
  email: '',
  initialize: async () => null,
  signIn: async () => null,
  signOut: async () => undefined,
  deleteAccount: async () => undefined,
  getAuthorizationToken: async () => '',
};

export const SessionContext = React.createContext<SessionState>(initialSession);

export const SessionProvider: FC<PropsWithChildren> = ({ children }) => (
  <SessionContext.Provider value={useSessionProvider()}>{children}</SessionContext.Provider>
);

const useSessionProvider = (): SessionState => {
  const sessionService = useSessionService();

  const [loggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');

  const resetState = () => {
    setLoggedIn(false);
    setUserId('');
    setEmail('');
  };

  const initialize = () =>
    Promise.all([sessionService.getUserId(), sessionService.getEmail()])
      .then(([userId, email]) => {
        batchedUpdates(() => {
          setLoggedIn(true);
          setUserId(userId);
          setEmail(email);
        });

        Analytics.setUser(userId, email);
        return { userId, email };
      })
      .catch(() => {
        setLoggedIn(false);
        return null;
      });

  const signIn = (email: string, password: string) =>
    sessionService
      .signIn(email, password)
      .then(initialize)
      .catch(error => {
        setLoggedIn(false);
        throw error;
      });

  const signOut = (options?: SignOutOptions) => sessionService.signOut(options).finally(resetState);

  const deleteAccount = () =>
    sessionService.deleteAccount().then(async () => {
      await signOut({ global: true });
    });

  const getAuthorizationToken = () =>
    sessionService.getIdJwt().catch(error => {
      setLoggedIn(false);
      throw error;
    });

  return {
    loggedIn,
    userId,
    email,
    initialize,
    signIn,
    signOut,
    deleteAccount,
    getAuthorizationToken,
  };
};

export const useSession = () => useContext(SessionContext) as SessionState;
