import { BarChart } from '@hiven-energy/hiven-charts';
import { ChargingSchedule } from '@hiven-energy/hiven-client';
import { Typography } from '@hiven-energy/hiven-ui';
import { useIsFocused } from '@react-navigation/native';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { average } from 'src/utils/average';
import { bounds } from 'src/utils/bounds';

import * as constants from './constants';
import { useRatioDimensions } from './hooks';
import * as styled from './styles';
import { Datum } from './types';
import { getReadyTimeTickWithOffset, parseData } from './utils';

const CHART_PADDING = 45;
const LINE_DASHARRAY: BarChart.Dasharray = '5,5';

interface Props {
  schedule: ChargingSchedule;
  prices: Record<string, number>;
}

const ScheduleChart: FC<Props> = ({ schedule, prices }) => {
  /* useIsFocused is needed to enforce chart rerender due to issue with ForeignObject
  reference: https://github.com/software-mansion/react-native-svg/issues/1357 */
  useIsFocused();

  const intl = useIntl();
  const dimensions = useRatioDimensions({ ratio: constants.ASPECT_RATIO, maxHeight: constants.MAX_HEIGHT });

  const [selectedIndex, setSelectedIndex] = useState<number>();

  const readyDate = useMemo(() => new Date(schedule.readyTime), [schedule.readyTime]);

  const data = useMemo(() => {
    const timeZone = schedule.timeZone === 'Z' ? undefined : schedule.timeZone;
    return parseData(schedule.hourlyCharges, prices, readyDate, timeZone);
  }, [schedule.hourlyCharges, schedule.timeZone, prices, readyDate]);

  const categoryTicks = useMemo(
    () => data.map(datum => datum.date.hour()).filter(hour => hour % constants.HOURS_RESOLUTION === 0),
    [data],
  );

  const [minPrice, maxPrice, averagePrice] = useMemo(() => {
    const values = Object.values(prices);
    return [...bounds(values), average(values)];
  }, [prices]);

  const valueTicks = useMemo(
    () => [averagePrice ?? 0, minPrice ?? 0, maxPrice ?? 0],
    [averagePrice, minPrice, maxPrice],
  );

  const readyTimeTickWithOffset = useMemo(() => readyDate && getReadyTimeTickWithOffset(readyDate), [readyDate]);

  const isScheduleOverime = data.some(datum => datum.category === 'overtime');

  const chartWrapperTestId = getChartWrapperTestId(data);

  const categoryLabelFormatter = useCallback((index: number) => data[index].date.hour().toString(), [data]);

  const valueLabelFormatter = useCallback(
    (value: number) => intl.formatNumber(value, { style: 'currency', currency: 'EUR' }),
    [],
  );

  const barsLabelFormatter = useCallback(
    (datum: Datum, index: number) => {
      const value = valueAccessor(datum);
      return selectedIndex === index ? valueLabelFormatter(value) : undefined;
    },
    [selectedIndex, valueLabelFormatter],
  );

  return (
    <styled.Container testID={chartWrapperTestId} accessibilityLabel={chartWrapperTestId}>
      <BarChart.Chart
        data={data}
        categoryTicks={categoryTicks}
        valueTicks={valueTicks}
        padding={CHART_PADDING}
        categoryAccessor={categoryAccessor}
        valueAccessor={valueAccessor}
        categoryLabelFormatter={categoryLabelFormatter}
        valueLabelFormatter={valueLabelFormatter}
        onPress={setSelectedIndex}
        {...dimensions}
      >
        <BarChart.CategoryAxis />
        <BarChart.CategoryAxisLabels />
        <BarChart.ValueAxisLabels />
        <BarChart.Bars fill={barsFill} />
        <BarChart.BarsLabels labelFormatter={barsLabelFormatter} />
        {minPrice !== undefined && <BarChart.ValueLine tick={minPrice} />}
        {maxPrice !== undefined && <BarChart.ValueLine tick={maxPrice} />}
        {averagePrice !== undefined && <BarChart.ValueLine tick={averagePrice} dasharray={LINE_DASHARRAY} />}
        {readyTimeTickWithOffset && <BarChart.CategoryLine {...readyTimeTickWithOffset} width={2} opacity={1} />}
      </BarChart.Chart>
      <styled.Legend>
        <styled.Circle $color={constants.categoryToBarColor.charge} />
        <Typography>
          <FormattedMessage id="priceSchedule.legend.charge" />
        </Typography>
      </styled.Legend>
      {isScheduleOverime && (
        <styled.Legend>
          <styled.Circle $color={constants.categoryToBarColor.overtime} />
          <Typography>
            <FormattedMessage id="priceSchedule.legend.overtime" />
          </Typography>
        </styled.Legend>
      )}
    </styled.Container>
  );
};

const categoryAccessor = (datum: Datum) => datum.index;

const valueAccessor = (datum: Datum) => datum.price;

const barsFill = (datum: Datum) => constants.categoryToBarColor[datum.category];

const getChartWrapperTestId = (data: Datum[]) => `PriceSchedule-Chart-data-${JSON.stringify(data)}`;

export default React.memo(ScheduleChart);
