import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';

export const useScreenDimensions = (): [number, number] => {
  const [dimensions, setDimensions] = useState<[number, number]>(() => {
    const { width, height } = Dimensions.get('screen');
    return [width, height];
  });

  useEffect(() => {
    const updateDimensions = () => {
      const { width, height } = Dimensions.get('window');
      setDimensions([width, height]);
    };
    const listener = Dimensions.addEventListener('change', updateDimensions);
    return () => listener.remove();
  }, []);

  return dimensions;
};
