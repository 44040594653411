import { Button, EmailIcon, Link, LockedIcon } from '@hiven-energy/hiven-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { FC, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';
import { z } from 'zod';

import { useA11y } from 'src/a11y';
import { TextField } from 'src/components/form-fields/TextField';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';
import { CognitoAction, useSessionService } from 'src/services/session';
import { code as codeRule, email as emailRule, password as passwordRule } from 'src/utils/validationRules';

import { getCognitoErrorMessageId } from '../utils';

import * as styled from './styles';
import { NewPasswordFormData, SendCodeFormData, Step } from './types';

type Props = ScreenProps<RouteId.ResetPassword>;

const ResetPassword: FC<Props> = ({ route, navigation }) => {
  const a11y = useA11y();
  const intl = useIntl();
  const toast = useToast();
  const sessionService = useSessionService();
  const { trackButtonClick } = useAnalytics();

  const [email, setEmail] = useState('');
  const [step, setStep] = useState(Step.SEND_CODE);
  const [loading, setLoading] = useState(false);

  const sendCodeForm = useForm<SendCodeFormData>({
    mode: 'onBlur',
    defaultValues: { email: route.params?.email || '' },
    resolver: useMemo(() => zodResolver(z.object({ email: emailRule(intl) })), [intl]),
  });

  const newPasswordForm = useForm<NewPasswordFormData>({
    mode: 'onBlur',
    defaultValues: {
      code: '',
      password: '',
    },
    resolver: useMemo(
      () =>
        zodResolver(
          z.object({
            password: passwordRule(intl, false),
            code: codeRule(intl),
          }),
        ),
      [intl],
    ),
  });

  const handleSendConfirmation = (data: SendCodeFormData) => {
    setLoading(true);
    trackButtonClick('ResetPassword.sendCode.confirm');
    sessionService
      .sendCode(data.email)
      .then(() => {
        setEmail(data.email);
        setStep(Step.NEW_PASSWORD);
        toast.show(intl.formatMessage({ id: 'ResetPassword.sendCode.success' }), { type: 'success' });
      })
      .catch(error => {
        const errorMessageId = getCognitoErrorMessageId(CognitoAction.SEND_CODE, error);
        toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResetPasswordConfirm = (data: NewPasswordFormData) => {
    setLoading(true);
    trackButtonClick('ResetPassword.newPassword.confirm');
    sessionService
      .resetPassword(email, data.code, data.password)
      .then(() => {
        navigation.navigate(RouteId.SignIn, { email });
        toast.show(intl.formatMessage({ id: 'ResetPassword.success' }), { type: 'success' });
      })
      .catch(error => {
        const errorMessageId = getCognitoErrorMessageId(CognitoAction.RESET_PASSWORD, error);
        toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBackPress = () => {
    trackButtonClick('ResetPassword.back');
    navigation.navigate(RouteId.SignIn);
  };

  return (
    <styled.Container>
      {step === Step.SEND_CODE && (
        <>
          <styled.Title variant="h3">
            <FormattedMessage id="ResetPassword.sendCode.header" />
          </styled.Title>
          <TextField
            placeholder={intl.formatMessage({ id: 'common.email' })}
            control={sendCodeForm.control}
            name="email"
            leftIcon={EmailIcon}
          />
          <styled.Footer>
            <Button
              title={intl.formatMessage({ id: 'ResetPassword.sendCode.confirm' })}
              disabled={loading}
              loading={loading}
              onPress={sendCodeForm.handleSubmit(handleSendConfirmation)}
            />
            <styled.Back>
              <Link accessibilityLabel={a11y.formatLabel('ResetPassword.back')} onPress={handleBackPress}>
                {intl.formatMessage({ id: 'ResetPassword.back' })}
              </Link>
            </styled.Back>
          </styled.Footer>
        </>
      )}
      {step === Step.NEW_PASSWORD && (
        <>
          <styled.Title variant="h3">
            <FormattedMessage id="ResetPassword.newPassword.header" />
          </styled.Title>
          <TextField
            placeholder={intl.formatMessage({ id: 'common.code' })}
            control={newPasswordForm.control}
            name="code"
          />
          <TextField
            placeholder={intl.formatMessage({ id: 'common.password' })}
            control={newPasswordForm.control}
            name="password"
            leftIcon={LockedIcon}
            password
          />
          <styled.Footer>
            <Button
              title={intl.formatMessage({ id: 'ResetPassword.newPassword.confirm' })}
              disabled={loading}
              loading={loading}
              onPress={newPasswordForm.handleSubmit(handleResetPasswordConfirm)}
            />
          </styled.Footer>
        </>
      )}
    </styled.Container>
  );
};

export default ResetPassword;
