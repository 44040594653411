import { CheckIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import background from 'src/assets/telematicaConnect/success/background.png';
import Hexagon from 'src/components/Hexagon/Hexagon';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { MixpanelEventProps } from 'src/services/analytics/types';

import * as styled from './styles';

interface Props {
  onContinue: VoidFunction;
  analytics: Pick<MixpanelEventProps<MixpanelEvents.VEHICLE_ADDED>, 'DeviceType' | 'Devices'>;
}

const Success: FC<Props> = ({ onContinue, analytics }) => {
  const intl = useIntl();

  const { trackVehicleAdded } = useAnalytics();

  const handleContinuePress = () => {
    trackVehicleAdded('common.continue', analytics.DeviceType, analytics.Devices);
    onContinue();
  };

  return (
    <styled.Container>
      <Hexagon>
        <CheckIcon />
      </Hexagon>
      <styled.Title variant="h1">
        <FormattedMessage id="TelematicaConnect.success" />
      </styled.Title>
      <styled.Button
        type="tertiary"
        title={intl.formatMessage({ id: 'common.continue' })}
        onPress={handleContinuePress}
      />
      <styled.ImageBackground source={background} />
    </styled.Container>
  );
};

export default Success;
