import { CarIcon, ChevronRightIcon, CrossIcon, ExternalIcon, PinIcon, Typography } from '@hiven-energy/hiven-ui';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import BaseView from 'src/components/BaseView/BaseView';
import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import { ScreenScrollView } from 'src/components/ScreenScrollView/ScreenScrollView';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';
import { useHivenClient } from 'src/services/hiven';
import { useSession } from 'src/store/session';
import { colors } from 'src/theme';

import * as styled from './styles';

type VisibleModalType = 'deleteAccount' | 'disconnectDevice' | 'chargerOcppSettings';

type Props = ScreenProps<RouteId.VehicleDashboard | RouteId.ChargerDashboard>;

const Settings: FC<Props> = ({ navigation }) => {
  const [visibleModalType, setVisibleModalType] = useState<VisibleModalType>();

  const intl = useIntl();
  const toast = useToast();
  const session = useSession();
  const hivenClient = useHivenClient();
  const { trackButtonClick, trackDeleteAccount, trackLoggedOut } = useAnalytics();

  const handleDeleteAccountPress = () => {
    trackButtonClick('Settings.deleteAccount');
    setVisibleModalType('deleteAccount');
  };

  const handleDeleteAccountConfirm = async () => {
    await hivenClient.signOut(session.userId);
    await session.deleteAccount();
    trackDeleteAccount('Settings.deleteAccount.modal.title');
    setVisibleModalType(undefined);
  };

  const handleDeleteAccountCancel = () => {
    setVisibleModalType(undefined);
  };

  const handleLogout = async () => {
    await session.signOut().catch(error => {
      toast.show(intl.formatMessage({ id: 'Settings.logout.error' }), { type: 'danger' });
      console.error('Could not sign out', error);
    });
    trackLoggedOut('Settings.logout');
  };

  const screenSection = (
    <>
      <styled.Setting
        onPress={() => {
          trackButtonClick('Settings.myVehiclesAndChargers');
          navigation.navigate(RouteId.MyVehiclesAndChargers);
        }}
      >
        <styled.Details>
          <CarIcon />
          <Typography fontWeight="medium">
            <FormattedMessage id="Settings.myVehiclesAndChargers" />
          </Typography>
        </styled.Details>
        <ChevronRightIcon color={colors.hintGrey} />
      </styled.Setting>

      <styled.Setting
        onPress={() => {
          trackButtonClick('Settings.myChargingLocations');
          navigation.navigate(RouteId.MyChargingLocations);
        }}
      >
        <styled.Details>
          <PinIcon />
          <Typography fontWeight="medium">
            <FormattedMessage id="Settings.myChargingLocations" />
          </Typography>
        </styled.Details>
        <ChevronRightIcon color={colors.hintGrey} />
      </styled.Setting>
    </>
  );

  const deleteAccountSection = (
    <>
      <styled.Setting onPress={handleDeleteAccountPress}>
        <styled.Details>
          <CrossIcon color={colors.punchRed} />
          <Typography fontWeight="medium" color={colors.punchRed}>
            <FormattedMessage id="Settings.deleteAccount" />
          </Typography>
        </styled.Details>
      </styled.Setting>
      {visibleModalType === 'deleteAccount' && (
        <ConfirmationModal
          title={intl.formatMessage({ id: 'Settings.deleteAccount.modal.title' })}
          content={intl.formatMessage({ id: 'Settings.deleteAccount.modal.content' })}
          onConfirm={handleDeleteAccountConfirm}
          onCancel={handleDeleteAccountCancel}
        />
      )}
    </>
  );

  const logoutSection = (
    <styled.Setting onPress={handleLogout} $skipBorderBottom>
      <styled.Details>
        <ExternalIcon />
        <Typography fontWeight="medium">
          <FormattedMessage id="Settings.logout" />
        </Typography>
      </styled.Details>
    </styled.Setting>
  );

  return (
    <BaseView>
      <ScreenScrollView verticalPadding={false}>
        {screenSection}
        {deleteAccountSection}
        {logoutSection}
      </ScreenScrollView>
    </BaseView>
  );
};

export default Settings;
