import React, { FC } from 'react';
import { Platform, ViewProps } from 'react-native';
import { Edge } from 'react-native-safe-area-context';

import * as styled from './styles';

interface Props extends ViewProps {
  safeAreaEdges?: Edge[];
}

const BaseView: FC<Props> = ({ children, safeAreaEdges = ['left', 'right', 'bottom'], ...props }) => (
  <styled.Container edges={safeAreaEdges}>
    <styled.KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} {...props}>
      {children}
    </styled.KeyboardAvoidingView>
  </styled.Container>
);

export default BaseView;
