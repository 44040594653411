import { ChargerPreferences, TariffInfo, VehiclePreferences } from '@hiven-energy/hiven-client';

import { MixpanelEvents } from './mixpanelEvents';
import { AnalyticsManager, MixpanelEventProps, PageInteraction, UserInteraction } from './types';

export abstract class CommonAnalyticsManager implements AnalyticsManager {
  abstract track<T extends MixpanelEvents>(type: T, props?: MixpanelEventProps<T>): void;

  abstract timeEvent(event: MixpanelEvents): void;

  abstract reset(): void;

  abstract identify(id: string): Promise<void>;

  abstract setPeople({ $email, $name }: { $email: string; $name: string }): void;

  async loggedIn(email: string) {
    this.track(MixpanelEvents.LOGGED_IN, { Email: email });
  }

  async setUser(userId: string, email: string) {
    await this.identify(userId);
    this.setPeople({ $email: email, $name: email.split('@')[0] });
  }

  loggedOut(actionProps: UserInteraction) {
    this.track(MixpanelEvents.LOGGED_OUT, actionProps);
    void this.reset();
  }

  signUp(email: string, actionProps: UserInteraction) {
    this.track(MixpanelEvents.SIGNED_UP, { Email: email, ...actionProps });
  }

  confirmSignUp(email: string, actionProps: UserInteraction) {
    this.track(MixpanelEvents.SIGNED_UP_CONFIRMED, { Email: email, ...actionProps });
  }

  pageView(pageName: string, params: Record<string, string> | undefined) {
    this.track(MixpanelEvents.PAGE_VIEWED, { PageName: pageName, Params: params });
  }

  pageViewStarted() {
    this.timeEvent(MixpanelEvents.PAGE_VIEWED);
  }

  registerWallboxAccount(email: string, actionProps: UserInteraction) {
    this.track(MixpanelEvents.WALLBOX_ACCOUNT_REGISTERED, { Email: email, ...actionProps });
  }

  registerCharger(name: string, actionProps: UserInteraction) {
    this.track(MixpanelEvents.CHARGER_REGISTERED, { Name: name, ...actionProps });
  }

  deleteAccount(actionProps: UserInteraction) {
    this.track(MixpanelEvents.ACCOUNT_DELETED, actionProps);
  }

  saveTariffInfo(tariffInfo: Partial<TariffInfo>, actionProps: UserInteraction) {
    this.track(MixpanelEvents.USER_DATA_SAVED, { ...tariffInfo, ...actionProps });
  }

  saveChargerPreferences(preferences: ChargerPreferences, deviceId: string, actionProps: UserInteraction) {
    this.track(MixpanelEvents.CHARGER_PREFERENCES_SAVED, { ...preferences, DeviceId: deviceId, ...actionProps });
  }

  saveVehiclePreferences(preferences: VehiclePreferences, deviceId: string, actionProps: UserInteraction) {
    this.track(MixpanelEvents.VEHICLE_PREFERENCES_SAVED, { ...preferences, DeviceId: deviceId, ...actionProps });
  }

  deleteDevice(deviceId: string, actionProps: UserInteraction) {
    this.track(MixpanelEvents.DEVICE_DELETED, { deviceId, ...actionProps });
  }

  userInteracted(actionProps: UserInteraction) {
    this.track(MixpanelEvents.USER_INTERACTED, actionProps);
  }

  userLocationSaved(locationId: string, locationName: string, pageInteraction: PageInteraction) {
    this.track(MixpanelEvents.USER_LOCATION_SAVED, { ...pageInteraction, locationId, locationName });
  }

  userOnboard(step: number, completed: boolean, actionProps: UserInteraction) {
    this.track(MixpanelEvents.USER_ONBOARDING, { Step: step, Completed: completed, ...actionProps });
  }

  vehicleAdded(deviceType: string | undefined, devices: string | undefined, actionProps: UserInteraction) {
    this.track(MixpanelEvents.VEHICLE_ADDED, { DeviceType: deviceType, Devices: devices, ...actionProps });
  }

  retryVehicleConnect(deviceType: string | undefined, devices: string | undefined, actionProps: UserInteraction) {
    this.track(MixpanelEvents.RETRY_VEHICLE_CONNECT, { DeviceType: deviceType, Devices: devices, ...actionProps });
  }

  retryVehicleConnectCancel(deviceType: string | undefined, devices: string | undefined, actionProps: UserInteraction) {
    this.track(MixpanelEvents.RETRY_VEHICLE_CONNECT_CANCEL, {
      DeviceType: deviceType,
      Devices: devices,
      ...actionProps,
    });
  }

  vehicleAssociated(deviceId: string, pageInteraction: PageInteraction) {
    this.track(MixpanelEvents.VEHICLE_ASSOCIATED, { ...pageInteraction, DeviceId: deviceId });
  }

  userWasWaitingForSchedule(scheduleVisible: boolean, pageInteraction: PageInteraction) {
    this.track(MixpanelEvents.USER_WAS_WAITING_FOR_SCHEDULE, {
      ...pageInteraction,
      ScheduleVisible: scheduleVisible,
    });
  }
}
