export enum CognitoAction {
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  SEND_CODE = 'sendCode',
  RESEND_CODE = 'resendCode',
  RESET_PASSWORD = 'resetPassword',
  CONFIRM_SIGN_UP = 'confirmSignUp',
}

export interface UserAttributes {
  userId: string;
  email: string;
}

export interface SignOutOptions {
  global?: boolean;
}
