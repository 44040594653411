import { ChargerChargingBehavior } from '@hiven-energy/hiven-client';
import { BatteryEmptyIcon, BatteryHalfIcon, EditIcon, FlashIcon, VehicleChargingIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { EditPanel } from 'src/components/EditPanel/EditPanel';
import { LabelValue } from 'src/components/LabelValue/LabelValue';
import ReadyTime from 'src/containers/ready-time/Dashboard/ReadyTime';
import { useVehicle } from 'src/queries/sdk';

import { chargeLevelUnit } from '../../constants';
import { Preferences as PreferencesType } from '../../types';

import * as styled from './styles';

interface Props {
  preferences: PreferencesType;
  onEditPress: VoidFunction;
}

const Preferences: FC<Props> = ({
  preferences: { setupType, associatedVehicleId, batteryCapacity, chargeLevels, chargingBehavior, readyTime },
  onEditPress,
}) => {
  const intl = useIntl();
  const vehicleQuery = useVehicle(associatedVehicleId!, { enabled: !!associatedVehicleId });

  return (
    <EditPanel
      titleLabel="ChargerDashboard.preferences.setUp.label"
      editButton={{
        title: intl.formatMessage({ id: 'common.edit' }),
        onPress: onEditPress,
        rightIcon: EditIcon,
      }}
    >
      <styled.Content>
        <ReadyTime readyTime={readyTime} />
        {vehicleQuery.data?.attributes.name && (
          <>
            <LabelValue
              icon={VehicleChargingIcon}
              label={intl.formatMessage({ id: 'ChargerPreferences.devicePairing.label' })}
              value={vehicleQuery.data.attributes.name}
            />
            <LabelValue
              icon={VehicleChargingIcon}
              label={intl.formatMessage({ id: 'ChargerPreferences.vehicleBatteryCapacity.label' })}
              value={`${batteryCapacity} kWh`}
            />
          </>
        )}
        <LabelValue
          icon={BatteryEmptyIcon}
          label={intl.formatMessage({ id: 'ChargerPreferences.chargeLevels.minimum.label' })}
          value={`${chargeLevels.minimum}${chargeLevelUnit[setupType]}`}
        />
        <LabelValue
          icon={BatteryHalfIcon}
          label={intl.formatMessage({ id: 'ChargerPreferences.chargeLevels.maximum.label' })}
          value={`${chargeLevels.maximum}${chargeLevelUnit[setupType]}`}
        />
        <LabelValue
          icon={FlashIcon}
          label={intl.formatMessage({ id: 'ChargerPreferences.chargingBehavior.label' })}
          value={
            chargingBehavior === ChargerChargingBehavior.SMART_CHARGE_ON
              ? intl.formatMessage({ id: 'charger.chargingBehavior.SMART_CHARGE_ON' })
              : intl.formatMessage({ id: 'charger.chargingBehavior.SMART_CHARGE_OFF' })
          }
        />
      </styled.Content>
    </EditPanel>
  );
};

export default React.memo(Preferences);
