import { DeviceType } from '@hiven-energy/hiven-client';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useA11y } from 'src/a11y';
import { MessageId } from 'src/i18n';

import * as styled from './styles';

interface Props {
  deviceName?: string;
  deviceType: DeviceType.CHARGER | DeviceType.VEHICLE;
  onSetPreferencesPress: VoidFunction;
}

const titleMessageId: Record<DeviceType.CHARGER | DeviceType.VEHICLE, MessageId> = {
  [DeviceType.CHARGER]: 'charger.Dashboard.noPreferences.title',
  [DeviceType.VEHICLE]: 'vehicle.Dashboard.noPreferences.title',
};

const contentMessageId: Record<DeviceType.CHARGER | DeviceType.VEHICLE, MessageId> = {
  [DeviceType.CHARGER]: 'charger.Dashboard.noPreferences.content',
  [DeviceType.VEHICLE]: 'vehicle.Dashboard.noPreferences.content',
};

const NoPreferences: FC<Props> = ({ deviceName = '', deviceType, onSetPreferencesPress }) => {
  const a11y = useA11y();
  const intl = useIntl();

  const setPreferencesButtonTitle =
    deviceType === DeviceType.VEHICLE
      ? intl.formatMessage({ id: 'vehicle.Dashboard.noPreferences.setPreferences' })
      : intl.formatMessage({ id: 'charger.Dashboard.noPreferences.setPreferences' });

  return (
    <styled.Container>
      <styled.Title variant="h4">
        <FormattedMessage id={titleMessageId[deviceType]} />
      </styled.Title>
      <styled.Content>
        <FormattedMessage id={contentMessageId[deviceType]} />
      </styled.Content>
      <styled.Button
        title={setPreferencesButtonTitle}
        onPress={onSetPreferencesPress}
        accessibilityLabel={a11y.formatLabel('noPreferences.setPreferences', { name: deviceName })}
        testID={a11y.formatLabel('noPreferences.setPreferences', { name: deviceName })}
      />
    </styled.Container>
  );
};

export default React.memo(NoPreferences);
