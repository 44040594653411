import { UserLocation } from '@hiven-energy/hiven-client';
import {
  ChainLinkIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  InfoIcon,
  TouchableContainer,
} from '@hiven-energy/hiven-ui';
import { useNavigation } from '@react-navigation/native';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useA11y } from 'src/a11y';
import ChargingSetup from 'src/containers/device-overview/ChargerChargingSetup/ChargerChargingSetup';
import { useToggle } from 'src/hooks/useToggle';
import { RouteId } from 'src/nav/types';
import { useCharger, useChargerPreferences } from 'src/queries/sdk';
import { colors } from 'src/theme';

import * as styled from './styles';

interface Props {
  chargerId: string;
  userLocations?: UserLocation[];
}

const ChargerSetup: FC<Props> = ({ chargerId, userLocations }) => {
  const a11y = useA11y();
  const navigation = useNavigation();

  const [open, toggleOpen] = useToggle(false);
  const [pressed, setPressed] = useState(false);

  const chargerQuery = useCharger(chargerId);
  const { data: chargerPreferences } = useChargerPreferences(chargerId);

  const handlePressIn = () => setPressed(true);

  const handlePressOut = () => setPressed(false);

  const handleOpenChargerDetailsPress = () => {
    navigation.navigate(RouteId.ChargerOverview, { deviceId: chargerId });
  };

  if (chargerQuery.isLoading || chargerQuery.isError) return null;

  const { data: charger } = chargerQuery;

  const ToggleIcon = open ? ChevronUpIcon : ChevronDownIcon;

  const togglerAccessibilityLabel = a11y.formatLabel('vehicle.Overview.ChargerSetup.toggler', {
    name: charger.attributes.name,
  });

  return (
    <styled.Container $pressed={pressed} $open={open}>
      <TouchableContainer
        testID={togglerAccessibilityLabel}
        accessibilityLabel={togglerAccessibilityLabel}
        onPressIn={handlePressIn}
        onPressOut={handlePressOut}
        onPress={toggleOpen}
      >
        <styled.Label>
          <FormattedMessage id="vehicle.deviceOverview.chargerSetup.title" />
        </styled.Label>
        <styled.Header>
          <ChainLinkIcon size={20} color={colors.deepNavy} />
          <styled.Name>{charger.attributes.name}</styled.Name>
          <ToggleIcon color={colors.hintGrey} />
        </styled.Header>
      </TouchableContainer>
      {open && (
        <>
          <styled.Divider />
          <styled.ChargerDetailsButton onPress={handleOpenChargerDetailsPress}>
            <styled.ChargerDetailsLabelWrapper>
              <InfoIcon size={20} color={colors.hintGrey} />
              <styled.ChargerDetailsLabel>
                <FormattedMessage id="vehicle.deviceOverview.chargerSetup.deviceDetails" />
              </styled.ChargerDetailsLabel>
            </styled.ChargerDetailsLabelWrapper>
            <ChevronRightIcon color={colors.deepNavy} />
          </styled.ChargerDetailsButton>
          <ChargingSetup
            charger={charger}
            preferences={chargerPreferences}
            userLocations={userLocations}
            showDividers={false}
          />
        </>
      )}
    </styled.Container>
  );
};

export default React.memo(ChargerSetup);
