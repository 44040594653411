import React, { FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';
import { useDebounce } from 'use-debounce';

import BaseView from 'src/components/BaseView/BaseView';
import Map from 'src/components/Map/Map';
import { useCurrentPosition } from 'src/hooks/useCurrentPosition';
import { usePlaceAutocompletePredictions, usePlaceDetails, usePlaceRegionPredictions } from 'src/queries/maps';
import { useSupportedCountries, useUserLocations } from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { AnalyticPlace } from 'src/services/analytics/types';
import { Address, PlacePrediction, Region } from 'src/services/maps/types';
import { createAddress, formatAddress, isFullAddress, isSameAddress } from 'src/utils/address';

import Confirmation from './Confirmation/Confirmation';
import PlaceAutocomplete from './PlaceAutocomplete/PlaceAutocomplete';

const DEBOUNCE = 1000;
const MAP_BOTTOM_PADDING = 50;
const DEFAULT_ZOOM = 10;

interface Props {
  region: Region;
  id?: string;
  address?: Required<Address>;
  onConfirm: (region: Region, address: Required<Address>, name: string) => void;
  analyticsPlace: AnalyticPlace;
  name?: string;
  onDetachChargingLocation?: VoidFunction;
  isLoading?: boolean;
}

const ChargingLocation: FC<Props> = props => {
  const intl = useIntl();
  const toast = useToast();
  const position = useCurrentPosition();

  const [search, setSearch] = useState(props.address ? formatAddress(props.address) : '');
  const [options, setOptions] = useState<PlacePrediction[]>([]);
  const [region, setRegion] = useState(props.region);
  const [placeId, setPlaceId] = useState<string>();
  const [address, setAddress] = useState<Address | undefined>(props.address);

  const userLocationsQuery = useUserLocations();
  const userLocations = userLocationsQuery.data || [];

  const supportedCountriesQuery = useSupportedCountries();
  const { trackButtonClick } = useAnalytics();

  const { data: supportedCountries } = supportedCountriesQuery;

  const isLocationSupported = useMemo(
    () => (address?.countryCode ? supportedCountries?.includes(address.countryCode) : undefined),
    [address, supportedCountries],
  );

  useEffect(() => {
    if (props.address || !position) return;
    setRegion(position);
  }, [props.address, position]);

  useEffect(() => {
    if (isLocationSupported !== false) return;
    toast.show(intl.formatMessage({ id: 'common.preferences.chargingLocation.preference.country.support.error' }), {
      type: 'danger',
    });
  }, [isLocationSupported]);

  const [debouncedSearch] = useDebounce(search, DEBOUNCE);

  const handleGetPredictionsError = () => {
    toast.show(intl.formatMessage({ id: 'common.preferences.chargingLocation.preference.predictions.fetch.error' }), {
      type: 'danger',
    });
  };

  usePlaceAutocompletePredictions(debouncedSearch, {
    enabled: !address && !!debouncedSearch,
    onSuccess: setOptions,
    onError: handleGetPredictionsError,
  });

  const handleGetPlaceError = () => {
    toast.show(intl.formatMessage({ id: 'common.preferences.chargingLocation.preference.place.fetch.error' }), {
      type: 'danger',
    });
  };

  usePlaceRegionPredictions(region, {
    enabled: !address,
    onSuccess: setOptions,
    onError: handleGetPredictionsError,
  });

  usePlaceDetails(placeId as string, {
    enabled: !!placeId,
    onSuccess: place => {
      setRegion(place.region);
      setAddress(place.address);
    },
    onError: handleGetPlaceError,
  });

  const handleValueChange = (value: string) => {
    setSearch(value);
    setPlaceId(undefined);
    setAddress(undefined);
  };

  const handleOptionSelect = (placeId: string) => {
    const { formattedAddress } = options.find(option => option.placeId === placeId) as PlacePrediction;
    setSearch(formattedAddress);
    setOptions([]);
    setPlaceId(placeId);
  };

  const handleRegionChange = (changedRegion: Region) => {
    setRegion(changedRegion);
    setSearch('');
    setOptions([]);
    setPlaceId(undefined);
    setAddress(undefined);
  };

  const handleConfirm = ({ name }: { name: string }) => {
    if (!address || !isFullAddress(address)) {
      toast.show(
        intl.formatMessage({ id: 'common.preferences.chargingLocation.preference.validation.address.error' }),
        {
          type: 'danger',
        },
      );
      return;
    }
    const filteredLocations = userLocations.filter(userLocation => userLocation.id !== props.id);
    const nameExists = filteredLocations.find(userLocation => userLocation.name === name);
    if (nameExists) {
      toast.show(
        intl.formatMessage({ id: 'common.preferences.chargingLocation.preference.validation.nameExists.error' }),
        {
          type: 'danger',
        },
      );
      return;
    }
    const addressExists = filteredLocations.find(userLocation => isSameAddress(createAddress(userLocation), address));
    if (addressExists) {
      toast.show(
        intl.formatMessage({ id: 'common.preferences.chargingLocation.preference.validation.addressExists.error' }),
        {
          type: 'danger',
        },
      );
      return;
    }
    props.onConfirm(region, address, name);
    trackButtonClick('common.preferences.chargingLocation.preference.confirmation.title', props.analyticsPlace);
  };

  const mapPadding = useMemo(
    () => ({
      top: 0,
      right: 0,
      bottom: address ? MAP_BOTTOM_PADDING : 0,
      left: 0,
    }),
    [address],
  );

  const showConfirmation = !!address && !!isLocationSupported;

  return (
    <BaseView safeAreaEdges={['left', 'right']}>
      <Map region={region} initialZoom={DEFAULT_ZOOM} onRegionChange={handleRegionChange} mapPadding={mapPadding} />
      <PlaceAutocomplete
        value={search}
        options={address ? [] : options}
        onValueChange={handleValueChange}
        onOptionSelect={handleOptionSelect}
      />
      <Confirmation
        visible={showConfirmation}
        onConfirm={handleConfirm}
        initialName={props.name}
        loading={props.isLoading}
        onDetachChargingLocation={props.onDetachChargingLocation}
      />
    </BaseView>
  );
};

export default React.memo(ChargingLocation);
