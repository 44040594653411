export enum MixpanelEvents {
  LOGGED_IN = 'Logged in',
  LOGGED_OUT = 'Logged out',
  SIGNED_UP = 'Signed-up',
  SIGNED_UP_CONFIRMED = 'Signed-up confirmed',
  PAGE_VIEWED = 'Page viewed',
  USER_INTERACTED = 'User interacted',
  WALLBOX_ACCOUNT_REGISTERED = 'Wallbox account registered',
  CHARGER_REGISTERED = 'Charger registered',
  ACCOUNT_DELETED = 'Account deleted',
  USER_DATA_SAVED = 'User data saved',
  CHARGER_PREFERENCES_SAVED = 'Charger preferences saved',
  VEHICLE_PREFERENCES_SAVED = 'Vehicle preferences saved',
  DEVICE_DELETED = 'Device deleted',
  USER_ONBOARDING = 'User onboarding',
  VEHICLE_ADDED = 'Vehicle added',
  RETRY_VEHICLE_CONNECT = 'Retry vehicle connect',
  RETRY_VEHICLE_CONNECT_CANCEL = 'Retry vehicle connect cancel',
  VEHICLE_ASSOCIATED = 'Vehicle associated',
  USER_WAS_WAITING_FOR_SCHEDULE = 'User was waiting for schedule',
  USER_LOCATION_SAVED = 'User location saved',
}
