import { VehicleChargingStatus, VehicleStatus } from '@hiven-energy/hiven-client';
import { Progress, ProgressStepBarType } from '@hiven-energy/hiven-ui';
import React, { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useAppTheme } from 'src/app-theme';
import { TogglePanel } from 'src/components/TogglePanel/TogglePanel';
import { DeviceList } from 'src/containers/DeviceList/DeviceList';
import LastHeartbeat from 'src/containers/LastHeartbeat/LastHeartbeat';
import { colors } from 'src/theme';

import {
  chargingStatusToIcon,
  chargingStatusToMessage,
  chargingStatusToProgressVariant,
  chargingStatusToStateOfChargeColor,
} from '../../constants';
import { ChargeLevels } from '../../types';

import * as styled from './styles';

type ProgressBarColors = Partial<Record<ProgressStepBarType, string>>;

interface Props {
  name: string | undefined;
  pairedChargerName?: string | undefined;
  lastHeartbeat?: string;
  chargeLevels: ChargeLevels;
  vehicleStatus: VehicleStatus | undefined;
  connectionPending: boolean;
  preferencesSet: boolean | undefined;
  deviceId: string | undefined;
  showDeviceList: boolean;
  onToggleDeviceList: VoidFunction;
}

const Status: FC<Props> = ({
  name,
  pairedChargerName,
  lastHeartbeat,
  chargeLevels,
  vehicleStatus,
  deviceId,
  connectionPending,
  preferencesSet,
  showDeviceList,
  onToggleDeviceList,
}) => {
  const intl = useIntl();
  const insets = useSafeAreaInsets();

  const appTheme = useAppTheme();

  const { soc, chargingStatus = VehicleChargingStatus.DISCONNECTED } = vehicleStatus || {};

  const socColor = chargingStatusToStateOfChargeColor[chargingStatus];
  const progressVariant = chargingStatusToProgressVariant[chargingStatus];
  const statusMessage = chargingStatusToMessage[chargingStatus];
  const StatusIcon = chargingStatusToIcon[chargingStatus];

  const progressBarColors = useMemo<ProgressBarColors>(
    () => ({
      [ProgressStepBarType.VALUE]:
        chargingStatus === VehicleChargingStatus.DISCONNECTED ? colors.pidgeonGray : undefined,
    }),
    [chargingStatus],
  );

  const formatPercents = (value: number) => intl.formatNumber(value / 100, { style: 'percent' });
  const title = name || '-';
  return (
    <styled.Container insets={insets} backgroundColor={appTheme.main.color}>
      <styled.Label>
        {pairedChargerName ? (
          <FormattedMessage id="ChargerDashboard.status.pairedWith" values={{ name: pairedChargerName }} />
        ) : (
          <FormattedMessage id="VehicleDashboard.status.label" />
        )}
      </styled.Label>
      <styled.Content>
        <TogglePanel
          subTitle={lastHeartbeat !== undefined && <LastHeartbeat heartbeat={lastHeartbeat} />}
          isActive={!!deviceId}
          startingHeight={160}
          title={<styled.Name>{title}</styled.Name>}
          toggleContent={deviceId && <DeviceList deviceId={deviceId} onPress={onToggleDeviceList} />}
          accessibilityLabel={title}
          analyticsPlace="VehicleDashboard.status"
          open={showDeviceList}
          onToggle={onToggleDeviceList}
        />
      </styled.Content>
      {connectionPending ? (
        <styled.Status>
          <FormattedMessage id="VehicleDashboard.status.connectionPending" />
        </styled.Status>
      ) : vehicleStatus ? (
        <>
          {soc !== undefined && (
            <styled.StateOfCharge fontSize={50} fontWeight="bold" color={socColor}>
              {formatPercents(soc)}
            </styled.StateOfCharge>
          )}
          <styled.StatusRow>
            <styled.IconLabelWrapper>
              <StatusIcon size={20} color={colors.white} />
              <styled.StatusLabel>
                <FormattedMessage {...statusMessage} />
              </styled.StatusLabel>
            </styled.IconLabelWrapper>
            {preferencesSet && (
              <styled.TargetLabel fontSize={14}>
                <FormattedMessage
                  id="VehicleDashboard.status.stopAt"
                  values={{ value: formatPercents(chargeLevels.maximum) }}
                />
              </styled.TargetLabel>
            )}
          </styled.StatusRow>
          <Progress.Step
            variant={progressVariant}
            value={soc}
            targetValue={chargeLevels.maximum}
            barColors={progressBarColors}
            animationDuration={150}
          />
        </>
      ) : (
        <styled.Status>
          <FormattedMessage id="VehicleDashboard.status.offline" />
        </styled.Status>
      )}
    </styled.Container>
  );
};

export default React.memo(Status);
