import { CheckIcon, ChevronRightIcon, IconProps } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { useA11y } from 'src/a11y';
import { colors } from 'src/theme';

import * as styled from './styles';

interface Props {
  name: string;
  onPress: VoidFunction;
  selected: boolean;
  icon: FC<IconProps>;
  online: boolean;
}

const DeviceItem: FC<Props> = ({ name, onPress, selected, icon: Icon, online }) => {
  const a11y = useA11y();

  return (
    <styled.Touchable
      accessibilityLabel={a11y.formatLabel('selectDevice.label', { name })}
      testID={a11y.formatLabel('selectDevice.label', { name })}
      disabled={selected}
      onPress={onPress}
      activeOpacity={0.5}
    >
      <styled.IconWrapper>
        <Icon color={colors.white} />
      </styled.IconWrapper>
      <styled.Details>
        <styled.Name
          variant="h2"
          accessibilityLabel={a11y.formatLabel('selectDevice.name')}
          testID={a11y.formatLabel('selectDevice.name')}
        >
          {name}
        </styled.Name>
        <styled.Status>
          <styled.StatusDot
            $color={online ? colors.aqua : colors.hintGrey}
            accessibilityLabel={online ? a11y.formatLabel('common.online') : a11y.formatLabel('common.offline')}
            testID={online ? a11y.formatLabel('common.online') : a11y.formatLabel('common.offline')}
          />
          <styled.StatusName variant="hint">
            <FormattedMessage id={online ? 'common.online' : 'common.offline'} />
          </styled.StatusName>
        </styled.Status>
      </styled.Details>
      {selected ? (
        <styled.CheckIconWrapper>
          <CheckIcon size={20} color={colors.deepNavy} />
        </styled.CheckIconWrapper>
      ) : (
        <ChevronRightIcon color={colors.aqua} />
      )}
    </styled.Touchable>
  );
};

export default React.memo(DeviceItem);
