import { Device, DeviceType } from '@hiven-energy/hiven-client';
import { Button, spacings } from '@hiven-energy/hiven-ui';
import { useNavigation } from '@react-navigation/native';
import partition from 'lodash/partition';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RefreshControl, ScrollView, SectionList, SectionListData, SectionListRenderItem } from 'react-native';

import { useA11y } from 'src/a11y';
import { useAppTheme } from 'src/app-theme';
import Loader from 'src/components/Loader/Loader';
import { RouteId } from 'src/nav/types';
import { useDevices } from 'src/queries/sdk';
import { useAnalytics } from 'src/services/analytics';
import { colors } from 'src/theme';
import { isCharger, isVehicle } from 'src/utils/device';

import { Charger } from './Charger';
import * as styled from './styles';
import { deviceTypeToSectionHeaderMap, prepareDeviceSections } from './utils';
import { Vehicle } from './Vehicle';

interface Props {
  deviceId: string;
  onPress?: VoidFunction;
}

export const DeviceList = ({ deviceId, onPress }: Props) => {
  const navigation = useNavigation();
  const appTheme = useAppTheme();
  const intl = useIntl();
  const a11y = useA11y();

  const { trackButtonClick, trackRefresh } = useAnalytics();
  const devicesQuery = useDevices();
  const devices = devicesQuery.data || [];

  const handleAddDevicePress = () => {
    trackButtonClick('deviceList.addDevice');
    if (onPress) onPress();
    navigation.navigate(RouteId.BrandGroups);
  };

  const renderDevice: SectionListRenderItem<Device> = ({ item }) => {
    if (isVehicle(item)) {
      return <Vehicle device={item} selectedDeviceId={deviceId} onPress={onPress} />;
    } else if (isCharger(item)) {
      return <Charger device={item} selectedDeviceId={deviceId} onPress={onPress} />;
    }
    return null;
  };

  if (devicesQuery.isLoading && !devicesQuery.data) {
    return <Loader fullScreen />;
  }

  const deviceSections = prepareDeviceSections(devices);

  const handleRefresh = async () => {
    await devicesQuery.refetch();
    trackRefresh();
  };

  const handleAssociationLinkPress = () => {
    if (onPress) onPress();
    navigation.navigate(RouteId.DevicePairing);
  };

  const [vehicles, chargers] = partition(devices, isVehicle);
  const isAssociationAllowed = vehicles.length > 0 && chargers.length > 0;

  const refreshControl = (
    <RefreshControl tintColor={colors.white} refreshing={devicesQuery.isLoading} onRefresh={handleRefresh} />
  );

  return (
    <styled.Container>
      {devices.length > 0 ? (
        <styled.List>
          <SectionList
            refreshControl={refreshControl}
            sections={deviceSections}
            ItemSeparatorComponent={() => <styled.Separator />}
            renderItem={renderDevice}
            renderSectionHeader={renderDeviceHeader(appTheme.main.color)}
            keyExtractor={deviceKeyExtractor}
            contentContainerStyle={{ paddingHorizontal: spacings.s }}
          />
        </styled.List>
      ) : (
        <ScrollView
          showsVerticalScrollIndicator={false}
          refreshControl={refreshControl}
          contentContainerStyle={{ alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}
        >
          <styled.EmptyListTitle variant="h3">
            <FormattedMessage id="deviceList.empty.title" />
          </styled.EmptyListTitle>
          <styled.EmptyListText>
            <FormattedMessage id="deviceList.empty.text" />
          </styled.EmptyListText>
        </ScrollView>
      )}
      <styled.ButtonContainer>
        {isAssociationAllowed && (
          <styled.AssociationLink
            onPress={handleAssociationLinkPress}
            styles={{
              title: {
                color: colors.smokeGrey,
              },
            }}
          >
            {intl.formatMessage({ id: 'deviceList.addDevice.contentTitle' })}
          </styled.AssociationLink>
        )}
        <Button
          type="secondary"
          title={intl.formatMessage({ id: 'common.addDevice' })}
          onPress={handleAddDevicePress}
          accessibilityLabel={a11y.formatLabel('common.addDevice')}
          testID={a11y.formatLabel('common.addDevice')}
          colors={{
            enabled: {
              titleColor: colors.smokeGrey,
            },
            pressed: {
              titleColor: colors.smokeGrey,
            },
          }}
        />
      </styled.ButtonContainer>
    </styled.Container>
  );
};

const renderDeviceHeader =
  (color: string) =>
  ({ section }: { section: SectionListData<Device, { type: DeviceType }> }) =>
    (
      <styled.SectionHeader variant="h4" $color={color}>
        <FormattedMessage id={deviceTypeToSectionHeaderMap[section.type]} />
      </styled.SectionHeader>
    );

const deviceKeyExtractor = (device: Device) => device.id;
