import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import { EdgeInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)<{ insets: EdgeInsets }>`
  width: 100%;
  padding-top: ${p => p.insets.top + spacings.xs}px;
  padding-right: ${spacings.xs}px;
  padding-bottom: ${spacings.xs}px;
  padding-left: ${spacings.xs}px;
  align-items: center;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  z-index: 1;
`;

export const Label = styled(Typography)`
  text-align: center;
  color: ${colors.white};
`;

export const Content = styled(_Container)`
  align-items: center;
`;

export const Status = styled(Typography).attrs({ variant: 'hint' })`
  color: ${colors.disabledGrey};
  margin-top: ${spacings.xxxxs}px;
`;

export const Name = styled(Typography).attrs({ variant: 'h1' })`
  text-align: center;
  color: ${colors.white};
`;

export const StateOfCharge = styled(Typography)`
  margin-top: ${spacings.xs}px;
  line-height: 50px;
  color: ${colors.white};
`;

export const StatusRow = styled(_Container)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${spacings.xs}px;
  margin-bottom: ${spacings.xxxs}px;
`;

export const IconLabelWrapper = styled(_Container)`
  flex-direction: row;
`;

export const StatusLabel = styled(Typography).attrs({ variant: 'h4' })`
  margin-left: ${spacings.xxxxs}px;
  color: ${colors.white};
`;

export const TargetLabel = styled(Typography)`
  color: ${colors.pidgeonGray};
`;
