import { Container as _Container, Link, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import BaseView from 'src/components/BaseView/BaseView';
import { colors } from 'src/theme';

export const Container = styled(BaseView)`
  flex: 1;
  flex-direction: column;
`;

export const List = styled(_Container)`
  flex: 1;
`;

export const ButtonContainer = styled(_Container)`
  flex-direction: column;
  justify-content: flex-end;
  padding: ${spacings.xs}px ${spacings.s}px ${spacings.s}px ${spacings.s}px;
`;

export const SectionHeader = styled(Typography)<{ $color: string }>`
  padding-top: ${spacings.m}px;
  padding-bottom: ${spacings.xxxs}px;
  color: ${colors.pidgeonGray};
  background-color: ${props => props.$color};
`;

export const EmptyListTitle = styled(Typography)`
  color: ${colors.white};
  margin-bottom: ${spacings.xxxs}px;
  text-align: center;
`;

export const EmptyListText = styled(Typography)`
  color: ${colors.white};
  text-align: center;
`;

export const AssociationLink = styled(Link)`
  margin-bottom: ${spacings.s}px;
  align-items: center;
`;

export const Separator = styled(_Container)`
  margin-bottom: ${spacings.xxs}px;
`;
