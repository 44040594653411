import { Vehicle as VehicleDevice } from '@hiven-energy/hiven-client';
import { CarIcon } from '@hiven-energy/hiven-ui';
import { useNavigation } from '@react-navigation/native';
import React, { FC, useState } from 'react';

import { RouteId } from 'src/nav/types';
import { useVehicleStatus } from 'src/queries/sdk';
import { isStatusOutdated } from 'src/utils/device';

import DeviceItem from '../DeviceItem';
import PairedVehicle from '../PairedVehicle';

const STATUS_REFRESH_INTERVAL_MS = 10 * 1000;

interface Props {
  device: VehicleDevice;
  selectedDeviceId: string;
  onPress?: VoidFunction;
}

export const Vehicle: FC<Props> = ({ device, selectedDeviceId, onPress }) => {
  const {
    id,
    attributes: { name },
    associatedChargerIds,
  } = device;

  const navigation = useNavigation();

  const [isConnected, setIsConnected] = useState(false);

  useVehicleStatus(id, {
    refetchInterval: STATUS_REFRESH_INTERVAL_MS,
    enabled: !isConnected,
    structuralSharing: false,
    onSuccess: status => {
      setIsConnected(!!status && !isStatusOutdated(status.timestamp));
    },
  });

  const handlePress = () => {
    if (onPress) onPress();
    navigation.push(RouteId.VehicleDashboard, { deviceId: id });
  };

  if (associatedChargerIds.length > 0) {
    return (
      <PairedVehicle
        id={id}
        name={name}
        onPress={handlePress}
        selectedDeviceId={selectedDeviceId}
        chargerIds={associatedChargerIds}
      />
    );
  }

  return (
    <DeviceItem
      name={name}
      onPress={handlePress}
      selected={id === selectedDeviceId}
      icon={CarIcon}
      online={isConnected}
    />
  );
};
