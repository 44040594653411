import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Language } from 'src/i18n';
import EnWeekdays from 'src/i18n/displayNames/weekday.en.json';
import FiWeekdays from 'src/i18n/displayNames/weekday.fi.json';

export type WeekDay = keyof (typeof FiWeekdays & typeof EnWeekdays);

export const useWeekDays = (): [WeekDay, string][] => {
  const intl = useIntl();

  return useMemo(() => {
    const weekdays = intl.locale === Language.EN ? EnWeekdays : FiWeekdays;
    return Object.entries(weekdays) as [WeekDay, string][];
  }, [intl]);
};
