import { CrossIcon } from '@hiven-energy/hiven-ui';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { Mode, useMode } from 'src/store/mode';
import { useSession } from 'src/store/session';

import * as styled from './styles';

const DemoAlert: FC = () => {
  const intl = useIntl();
  const session = useSession();
  const [mode, changeMode] = useMode();

  const handleDisableClick = () => {
    session.signOut().then(() => changeMode(Mode.LIVE));
  };

  if (mode !== Mode.DEMO) return null;

  return (
    <styled.Wrapper>
      <styled.Button
        leftIcon={CrossIcon}
        title={intl.formatMessage({ id: 'demoMode.disable' })}
        onPress={handleDisableClick}
      />
    </styled.Wrapper>
  );
};

export default React.memo(DemoAlert);
