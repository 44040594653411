import { Container as _Container, spacings, Typography } from '@hiven-energy/hiven-ui';
import styled from 'styled-components/native';

import { colors } from 'src/theme';

export const Container = styled(_Container)`
  padding: ${spacings.xxs}px;
  border-width: 1px;
  border-radius: 12px;
  border-color: ${colors.pidgeonGray};
`;

export const Header = styled(_Container)`
  flex-direction: row;
  align-items: center;
`;

export const Title = styled(Typography)`
  flex-grow: 1;
  color: ${colors.deepNavy};
`;

export const Period = styled(Typography)`
  font-size: 16px;
  color: ${colors.stoneGrey};
`;

export const Content = styled(_Container)`
  margin-top: ${spacings.xxs}px;
`;

export const Hint = styled(Typography)`
  margin-bottom: ${spacings.xxxxs}px;
`;

export const ChartWrapper = styled(_Container)`
  margin-top: ${spacings.xxxs}px;
  margin-bottom: ${spacings.xxxs}px;
`;
