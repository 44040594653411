import { SignInFormData, SignInRoutedProps } from './types';

export const signInDefaultValues = (routedParams?: SignInRoutedProps) => {
  const emailFromParams = routedParams?.email;

  const defaultValues: SignInFormData = {
    email: emailFromParams || '',
    password: '',
  };

  return defaultValues;
};
