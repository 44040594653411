import { useFocusEffect } from '@react-navigation/native';
import { useCallback, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { QueryOptions } from '../queries/types';

export const useRefetchIntervalOnFocus = <T, E, D>(options?: QueryOptions<T, E, D>) => {
  const [isInFocus, setIsInFocus] = useState(false);

  useFocusEffect(() => {
    setIsInFocus(true);

    return () => setIsInFocus(false);
  });

  const refreshIntervalOnFocus = useCallback(() => {
    return (Platform.OS === 'web' || isInFocus) && options ? options.refetchInterval : undefined;
  }, [isInFocus, options]);

  return useMemo<QueryOptions<T, E, D> | undefined>(
    () =>
      options && {
        ...options,
        refetchInterval:
          typeof options.refetchInterval === 'number' ? refreshIntervalOnFocus() : options.refetchInterval,
      },
    [refreshIntervalOnFocus, options],
  );
};
