import { Button } from '@hiven-energy/hiven-ui';
import React, { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import { useA11y } from 'src/a11y';
import CodeField, { CodeControlType } from 'src/components/form-fields/CodeField';
import { RouteId, ScreenProps } from 'src/nav/types';
import { useAnalytics } from 'src/services/analytics';
import { MixpanelEvents } from 'src/services/analytics/mixpanelEvents';
import { useAnalyticsTimeEvent } from 'src/services/analytics/useAnalyticsTimeEvent';
import { CognitoAction, useSessionService } from 'src/services/session';

import { getCognitoErrorMessageId } from '../utils';

import { confirmSignUpDefaultValues } from './functions';
import ResendCode from './ResendCode/ResendCode';
import * as styled from './styles';
import { ConfirmSignUpFormData } from './types';

type Props = ScreenProps<RouteId.ConfirmSignUp>;

const confirmTitle = { id: 'ConfirmSignUp.button' };

const ConfirmSignUp: FC<Props> = ({ route, navigation }) => {
  const intl = useIntl();
  const a11y = useA11y();

  const toast = useToast();
  const sessionService = useSessionService();

  useAnalyticsTimeEvent(MixpanelEvents.SIGNED_UP_CONFIRMED);
  const { trackConfirmSignUp } = useAnalytics();

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
  } = useForm<ConfirmSignUpFormData>({ mode: 'onChange', defaultValues: confirmSignUpDefaultValues(route.params) });

  const [loading, setLoading] = useState(false);

  const confirmSignUp = useCallback(
    (data: ConfirmSignUpFormData) => {
      setLoading(true);

      sessionService
        .confirmSignUp(data.email, data.code)
        .then(() => {
          navigation.navigate(RouteId.SignIn, { email: data.email });
          trackConfirmSignUp(confirmTitle.id, data.email);
          toast.show(intl.formatMessage({ id: 'ConfirmSignUp.success' }), { type: 'success' });
        })
        .catch(error => {
          const errorMessageId = getCognitoErrorMessageId(CognitoAction.CONFIRM_SIGN_UP, error);
          toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [sessionService, toast],
  );

  return (
    <styled.Container>
      <styled.Title variant="h3" accessibilityLabel={a11y.formatLabel('ConfirmSignUp.header')}>
        <FormattedMessage id="ConfirmSignUp.header" />
      </styled.Title>
      <CodeField control={control as unknown as CodeControlType} />
      <styled.Footer>
        <Button
          disabled={!isValid}
          loading={loading}
          title={intl.formatMessage(confirmTitle)}
          onPress={handleSubmit(confirmSignUp)}
          testID={a11y.formatLabel('ConfirmSignUp.button')}
        />
        <ResendCode
          sendCode={!!route.params.sendCode}
          getEmail={() => getValues().email}
          getEmailFieldError={() => errors.email?.message}
        />
      </styled.Footer>
    </styled.Container>
  );
};

export default ConfirmSignUp;
