import { TELEMATICA_SIMULATOR } from '@hiven-energy/hiven-client';
import { useMemo } from 'react';

import { Mode, useMode } from 'src/store/mode';

import * as constants from './constants';

export const useBrands = () => {
  const [mode] = useMode();
  const brands = useMemo(
    () => constants.brands.filter(brand => mode === Mode.SIMULATED || brand.manufacturer !== TELEMATICA_SIMULATOR),
    [mode],
  );
  return brands;
};
