import { Link } from '@hiven-energy/hiven-ui';
import React, { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToast } from 'react-native-toast-notifications';

import { useA11y } from 'src/a11y';
import { useAnalytics } from 'src/services/analytics';
import { CognitoAction, useSessionService } from 'src/services/session';

import { getCognitoErrorMessageId } from '../../utils';

import * as styled from './styles';

interface Props {
  sendCode: boolean;
  getEmail: () => string;
  getEmailFieldError: () => string | undefined;
}

const ResendCode: FC<Props> = ({ sendCode, getEmail, getEmailFieldError }) => {
  const intl = useIntl();
  const a11y = useA11y();

  const toast = useToast();
  const sessionService = useSessionService();
  const { trackButtonClick } = useAnalytics();

  useEffect(() => {
    if (sendCode) {
      resend();
    }
  }, [sendCode]);

  const resend = useCallback(() => {
    const email = getEmail();
    const emailFieldError = getEmailFieldError();

    if (!emailFieldError && !!email) {
      sessionService
        .resendCode(email)
        .then(() => {
          toast.show(intl.formatMessage({ id: 'ConfirmSignUp.resend.success' }), { type: 'success' });
        })
        .catch(error => {
          const errorMessageId = getCognitoErrorMessageId(CognitoAction.RESEND_CODE, error);
          toast.show(<FormattedMessage id={errorMessageId} />, { type: 'danger' });
        });
    } else {
      toast.show(intl.formatMessage({ id: 'ConfirmSignUp.resend.missingEmail' }), { type: 'danger' });
    }
  }, [getEmail, getEmailFieldError, sessionService, toast]);

  const handleResend = useCallback(() => {
    trackButtonClick('ConfirmSignUp.resendLink');
    resend();
  }, [resend, trackButtonClick]);

  return (
    <styled.Container>
      <styled.ResendCodeQuestion
        fontSize={14}
        fontWeight="medium"
        accessibilityLabel={a11y.formatLabel('ConfirmSignUp.resendQn')}
      >
        <FormattedMessage id="ConfirmSignUp.label.resend.question" />
      </styled.ResendCodeQuestion>
      <Link onPress={handleResend} accessibilityLabel={a11y.formatLabel('ConfirmSignUp.resendLink')}>
        {intl.formatMessage({ id: 'ConfirmSignUp.label.resend.link' })}
      </Link>
    </styled.Container>
  );
};

export default React.memo(ResendCode);
